import React from 'react';
import MultiSelect from '../../../../shared/form/components/MultiSelect';

export default function SessionMulti({
  placeholder,
  form,
  label,
  req,
  isSingleSelect = true,
  ...props
}) {
  return (
    <MultiSelect
      label={label}
      fieldWidth={500}
      fontWeight={500}
      labelColor="#949494"
      formContext={form}
      placeholder={placeholder || `Select ${label}`}
      valueKey={'value'}
      labelKey="label"
      isSingleSelect={isSingleSelect}
      findValueInOptions={true}
      {...(req && { formFieldOptions: { required: `${label} Is Required` } })}
      {...props}
    />
  );
}
