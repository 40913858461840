import React from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import scanTargetGroupsApi from '../api/scanTargetGroupsApi';
import { QrScannerConstants } from '../definitions';
import useQrTargetGroupActions from '../hooks/useQrTargetGroupActions';

function ScannerTargetGroups() {
  const { remove, add, back } = useQrTargetGroupActions(QrScannerConstants);
  const { groupsPageId, typeName } = QrScannerConstants;

  const toolbarActions = useArray(add);
  const itemActions = useArray(remove);

  const columns = [
    {
      label: 'Name',
      valueField: 'name',
      sortable: true,
    },
  ];

  return (
    <CmsPage title={'QR Scanner Target Groups'} backTitle="QR Scanner Targets" backClick={back}>
      <CmsItemListSection
        pageId={groupsPageId}
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={columns}
        itemApi={scanTargetGroupsApi}
      />
    </CmsPage>
  );
}

export default ScannerTargetGroups;
