import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import Spinner from '../../shared/spinner/components/Spinner';
import pushNotificationApi from '../api/pushNotificationApi';
import { PushNotificationConstants } from '../definitions';
import PushNotificationEditorForm from './PushNotificationEditorForm';

const PushNotificationsEditorPage = (props) => {
  const { pushNotificationId, allowEdit, baseNotificationId } = props;
  const { item, loadItem, saveItem, requestPending } = useItemPersistence(pushNotificationApi);

  useEffect(() => {
    if (pushNotificationId === 'new' && baseNotificationId)
      (async () => await loadItem(baseNotificationId))();
    else if (pushNotificationId) (async () => await loadItem(pushNotificationId))();
  }, [pushNotificationId, baseNotificationId, loadItem]);

  const form = useCmsForm(item);
  const isFutureScheduled = useMemo(() => {
    return (
      item?.status === 'scheduled' &&
      item?.sendTimestamp &&
      moment(item.sendTimestamp).isAfter(moment().add(10, 'minutes'))
    );
  }, [item]);
  const viewOnly = pushNotificationId !== 'new' && !allowEdit && !isFutureScheduled;
  const rawActions = useItemEditActions(saveItem, form, PushNotificationConstants);
  const actions = viewOnly ? rawActions.filter((a) => a.label === 'Cancel') : rawActions;
  const titleDescription =
    pushNotificationId === 'new' && !baseNotificationId ? 'Add' : viewOnly ? 'View' : 'Copy';
  return (
    <CmsPage
      className="push-notification-editor-page"
      title={`${titleDescription} Push Notification`}
    >
      {!!item && !requestPending ? (
        <PushNotificationEditorForm
          viewOnly={viewOnly}
          form={form}
          isFuture={item?.status === 'scheduled'}
          isFutureScheduled={isFutureScheduled}
          growlId={PushNotificationConstants.editorPageName}
          actions={actions}
        />
      ) : (
        <Spinner />
      )}
    </CmsPage>
  );
};

export default PushNotificationsEditorPage;
