import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import { navigateToChildPage } from '../../store/rootReducer';
import cohortApi from '../api/cohortApi';
import { CohortConstants } from '../definitions';
const useCohortListActions = (showCohortEditorModal, setDeletionData) => {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [prevDeletionData, setPrevDeletionData] = useState({});
  const { add, edit, remove, requestPending } = useItemListActions(cohortApi, CohortConstants);
  const addOverride = useMemo(
    () => ({
      ...add,

      onClick: async ({ loaderParams }) => {
        const newCohort = await cohortApi.loadRecord('new');
        const savedItem = await showCohortEditorModal(newCohort);
        if (savedItem) {
          dispatch(
            navigateToChildPage([
              CohortConstants.pluginId,
              CohortConstants.editorPageName,
              CohortConstants.defaultPageId,
              loaderParams,
              savedItem.id,
            ])
          );
        }
      },
    }),
    [add, cohortApi, dispatch, showCohortEditorModal]
  );

  const removeOverride = useMemo(
    () => ({
      ...remove,
      onClick: async (item, params) => {
        const { reload } = params;
        await cohortApi.removeRecord(item);
        reload();
      },
    }),
    [remove]
  );

  const usage = useMemo(
    () => ({
      icon: remove.icon,
      label: 'Remove',
      onClick: async (item, rest) => {
        const data = await cohortApi.usage(item.id);
        if (prevDeletionData.rest && !rest) {
          rest = prevDeletionData.rest;
        }
        data.rest = rest;
        setDeletionData(data);
        setPrevDeletionData(data);
        setShowDeleteModal(true);
      },
    }),
    [cohortApi, setDeletionData, setShowDeleteModal, prevDeletionData]
  );
  return useMemo(
    () => ({
      add: addOverride,
      edit,
      removeCohort: removeOverride.onClick,
      remove: usage,
      requestPending,
      setShowDeleteModal,
      showDeleteModal,
    }),
    [addOverride, edit, remove, requestPending]
  );
};

export default useCohortListActions;
