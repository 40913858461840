import { Select as MUISelect, MenuItem } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import useFieldProps from '../hooks/useFieldProps';
import FieldWrapper from './FieldWrapper';

const ProvidedSelect = (props) => {
  const {
    label,
    fieldName,
    placeholder,
    formFieldOptions,
    formContext,
    selectOptions,
    selectChange = () => {},
    variant = 'standard',
  } = props;
  const { isRequired, value, inputAttributes } = useFieldProps(props, true);
  const { control } = formContext;

  return (
    <FieldWrapper {...props} showLabel controlProps showError>
      <Controller
        name={fieldName}
        control={control}
        rules={formFieldOptions}
        defaultValue={value || ''}
        render={({ field }) => (
          <MUISelect
            {...field}
            label={label}
            variant={variant}
            required={isRequired}
            onChange={(e) => {
              field.onChange(e);
              const selectedValue = e.target.value;
              const selectedOption = selectOptions.find((opt) => opt.value === selectedValue);
              selectChange(selectedOption);
            }}
            {...inputAttributes}
          >
            {placeholder && (
              <MenuItem value="" disabled>
                {placeholder}
              </MenuItem>
            )}
            {selectOptions &&
              selectOptions.map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
          </MUISelect>
        )}
      />
    </FieldWrapper>
  );
};

export default ProvidedSelect;
