import React, { useCallback } from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { ManageGroupsConstants } from '../definitions';
import useModifiedItemPersistance from '../hooks/useModifiedItemPersistance';
import ManageGroupsEditorForm from './ManageGroupsEditorForm';
import { setHasQueriedOptions } from '../../shared/form/components/AudienceSelector/store';
import { useDispatch } from 'react-redux';
const AddGroup = ({ groupId }) => {
  const { item, saveItem } = useModifiedItemPersistance(groupId);
  const form = useCmsForm(item);
  const dispatch = useDispatch();
  const createGroup = useCallback(
    async (...params) => {
      const resp = await saveItem(...params);
      dispatch(setHasQueriedOptions({ optsName: 'Group', queried: false }));
      return resp;
    },
    [dispatch, saveItem]
  );
  const actions = useItemEditActions(createGroup, form, ManageGroupsConstants);
  return (
    <CmsPage title={`${groupId === 'new' ? 'Add' : 'Edit'} Group`}>
      <ManageGroupsEditorForm
        groupId={groupId}
        form={form}
        growlId={ManageGroupsConstants.editorPageName}
        actions={actions}
      />
    </CmsPage>
  );
};

export default AddGroup;
