import React, { useState } from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import cohortApi from '../api/cohortApi';
import { CohortConstants } from '../definitions';
import useItemEditorModal from '../../shared/cmsPage/hooks/useItemEditorModal';
import CohortEditorForm from './CohortEditorForm';
import ItemEditorModal from '../../shared/cmsPage/components/ItemEditorModal';
import useCohortListActions from '../hooks/useCohortListActions';
import CohortRemoveModal from './CohortRemoveModal';
const CohortColumns = [
  {
    label: 'Name',
    valueField: 'name',
    sortable: true,
    initialSort: 'ASC',
  },
];

const CohortListPage = () => {
  const [deletionData, setDeletionData] = useState({});
  const { defaultPageId, typeName } = CohortConstants;
  const { showItemEditorModal, editorModalProps } = useItemEditorModal(
    CohortConstants.typeName,
    'name',
    cohortApi,
    CohortEditorForm
  );
  const { add, edit, removeCohort, requestPending, remove, showDeleteModal, setShowDeleteModal } =
    useCohortListActions(showItemEditorModal, setDeletionData);
  const toolbarActions = useArray(add);
  const itemActions = useArray(edit, remove);
  return (
    <CmsPage title={CohortConstants.pageTitle} requestPending={requestPending}>
      <CmsItemListSection
        pageId={defaultPageId}
        className="cohort-manager"
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={CohortColumns}
        itemApi={cohortApi}
      />
      <ItemEditorModal {...editorModalProps} />
      <CohortRemoveModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        remove={removeCohort}
        update={remove.onClick}
        setDeletionData={setDeletionData}
        {...deletionData}
      />
    </CmsPage>
  );
};

export default CohortListPage;
