import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import log from '../../logger';
import { navigateToPluginPage } from '../../store/rootReducer';
import { errorGrowl, successGrowl } from '../../store/rootThunks';
import scanTargetsApi from '../api/scanTargetsApi';
import { QrScannerConstants as pageConstants } from '../definitions';

export default function useQrTargetActions() {
  const dispatch = useDispatch();

  const add = useMemo(
    () => ({
      label: `+ New Target`,
      onClick: async () => {
        try {
          const newQrScannerTarget = await scanTargetsApi.loadRecord('new');
          dispatch(
            navigateToPluginPage([
              pageConstants.pluginId,
              pageConstants.targetEditorPageName,
              newQrScannerTarget?.id || 'new',
            ])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: pageConstants.defaultPageId, props: 'Failed to add.' }));
        }
      },
    }),
    [dispatch, pageConstants]
  );
  const remove = useMemo(
    () => ({
      label: 'Remove',
      icon: 'fa-solid fa-trash',
      onClick: async (context, { reload }) => {
        try {
          const confirmResponse = await confirm({
            title: 'Delete QrScanner',
            message: `Are you sure you want to delete '${context.name}'?`,
          });

          if (confirmResponse) {
            await scanTargetsApi.removeRecord(context.id);
            await reload();
            dispatch(
              successGrowl({
                groupId: pageConstants.defaultPageId,
                props: 'Successfully removed qrscanner.',
              })
            );
          }
        } catch (e) {
          log.error(e);
          dispatch(
            errorGrowl({
              groupId: pageConstants.defaultPageId,
              props: 'Failed to update qrscanner.',
            })
          );
        }
      },
    }),
    [dispatch, pageConstants.pluginId, pageConstants.defaultPageId, confirm]
  );
  const edit = useMemo(
    () => ({
      icon: 'fa-solid fa-pencil',
      label: 'Edit',
      disabled: (context) => context.status === 'removed',
      onClick: async (context) => {
        try {
          const newQrScannerTarget = await scanTargetsApi.loadRecord(context.id);
          dispatch(
            navigateToPluginPage([
              pageConstants.pluginId,
              pageConstants.targetEditorPageName,
              newQrScannerTarget.id || 'new',
            ])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: pageConstants.defaultPageId, props: 'Failed to add.' }));
        }
      },
    }),
    [dispatch, pageConstants.pluginId, pageConstants.defaultPageId]
  );
  const manageGroups = useMemo(
    () => ({
      label: 'Manage Groups',
      icon: 'fa-solid fa-users',
      onClick: async (context) => {
        try {
          dispatch(
            navigateToPluginPage([
              pageConstants.pluginId,
              pageConstants.targetGroupsPageName,
              context.id,
            ])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: pageConstants.defaultPageId, props: 'Failed to add.' }));
        }
      },
    }),
    [dispatch, pageConstants.pluginId, pageConstants.defaultPageId]
  );

  return {
    add,
    remove,
    edit,
    manageGroups,
  };
}
