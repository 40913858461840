import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import CmsPage from '../../../shared/cmsPage/components/CmsPage';
import travelApi from '../../api/travelApi';
import { BackToSession } from '../BackButtons';
import ItrsTravelConfirmSection from './ItrsTravelConfirmSection';
import { tryParse } from '../../../shared/util/utils';

const Heading = ({ children, subheading = false, sx = {}, ...rest }) => (
  <Typography
    variant={subheading ? 'subtitle1' : 'h6'}
    sx={(theme) => ({
      backgroundColor: subheading ? theme.palette.bootstrap.grey : theme.palette.bootstrap.blue,
      color: 'white',
      padding: theme.spacing(1, 1),
      ...sx,
    })}
    {...rest}
  >
    {children}
  </Typography>
);

const Dt = ({ label, value, truthy, array }) => {
  if (array) {
    value = tryParse(value);
    if (Array.isArray(value)) value = value.join(', ');
  }
  return (
    (!truthy || !!value) && (
      <>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
              {`${label}:`}
            </Typography>
          </Box>
          <Box sx={{ pl: '1rem' }}>
            <Typography variant="body1" gutterBottom>
              {value}
            </Typography>
          </Box>
        </Box>
      </>
    )
  );
};

const Section = ({ children, name, title }) => (
  <Accordion>
    <AccordionSummary
      sx={{ backgroundColor: '#EEEEEE' }}
      expandIcon={<ArrowDropDownIcon />}
      aria-controls={`${name}-content`}
      id={`${name}-header`}
    >
      <Typography>{title}</Typography>
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
);

const ItrsTravelConfirmationPage = ({ travelRequestId, sessionId, returnTo }) => {
  const theme = useTheme();
  const [item, setItem] = useState({});

  useEffect(() => {
    (async () => {
      setItem(await travelApi().loadRecord(travelRequestId));
    })();
  }, [travelRequestId]);

  const tc = item?.travelConfirmation || {};
  const {
    ADA_NEEDS,
    ADDITIONAL_INFO,
    ADDITIONAL_REQUESTS,
    ADDITIONAL_REQUESTS_DORM,
    AIR_CANADA_AEROPLAN,
    AMERICAN_ADVANTAGE,
    DATE_OF_BIRTH,
    DELTA_SKYMILES,
    DEPARTURE_AIRPORT,
    DEPARTURE_CITY,
    DEPARTURE_DATE,
    DEPARTURE_TIME,
    DIETARY_NEEDS,
    DORM_CHECK_IN_DATE,
    DORM_CHECK_IN_TIME,
    DORM_CHECK_OUT_DATE,
    DORM_CHECK_OUT_TIME,
    GENDER,
    JET_BLUE_TRUE_BLUE,
    MIDDLE_NAME,
    MOBILE,
    NEED_AIRFARE,
    NEED_DORM_ROOM,
    NEED_GROUND_TRANSPORTATION,
    OTHER_AIRLINE_REWARDS,
    PREFERRED_AIRPORT,
    PREFERRED_DEPARTURE_AIRLINE,
    PREFERRED_DEPARTURE_FLIGHT_NUMBER,
    PREFERRED_RETURN_AIRLINE,
    PREFERRED_RETURN_FLIGHT_NUMBER,
    RETURN_DATE,
    RETURN_TIME,
    SEATING_PREFERENCE,
    SOUTHWEST_RAPID_REWARDS,
    TSA_PRECHECK,
    UNITED_MILEAGE_PLUS,
    VIRGIN_AMERICA_ELEVATE,
    WEST_JET_REWARDS,
    email,
    firstName,
    lastName,
  } = item?.formInputs || {};
  const fullName = `${firstName} ${MIDDLE_NAME} ${lastName}`;

  return (
    <CmsPage>
      <Box sx={{ padding: theme.spacing(0) }}>
        <BackToSession sessionId={sessionId} returnTo={returnTo} />
        <Box sx={{ height: '2rem' }} />
        <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(2) }}>
          <div>
            <Typography variant="h4" component="h1" sx={{ flexGrow: 1 }}>
              {`Travel Request for ${fullName}`}
            </Typography>
          </div>
        </Box>

        <Box sx={{ my: theme.spacing(3) }}>
          <Heading gutterBottom sx={{ mb: theme.spacing(1) }}>
            Traveller Information
          </Heading>

          <Dt label="First Name" value={firstName} />
          <Dt label="Middle Name" value={MIDDLE_NAME} />
          <Dt label="Last Name" value={lastName} />
          <Dt label="Date of Birth" value={DATE_OF_BIRTH} />
          <Dt label="Gender" value={GENDER} />
          <Dt label="Cell Phone" value={MOBILE} />
          <Dt label="Email Address" value={email} />
          <Section name="dorm" title="Dorm Room Information">
            <Dt label="Needs a Dorm Room" value={NEED_DORM_ROOM ? 'Yes' : 'No'} />
            <Dt label="Check-In Date" value={DORM_CHECK_IN_DATE} />
            <Dt label="Check-In Time" value={DORM_CHECK_IN_TIME} />
            <Dt label="Check-Out Date" value={DORM_CHECK_OUT_DATE} />
            <Dt label="Check-Out Time" value={DORM_CHECK_OUT_TIME} />
            <Dt label="Additional Requests" value={ADDITIONAL_REQUESTS_DORM} />
          </Section>
          <Section name="airfare" title="Airfare Information">
            <Dt label="Needs Airfare" value={NEED_AIRFARE ? 'Yes' : 'No'} />
            <Dt label="Departure Date" value={DEPARTURE_DATE} />
            <Dt label="Departure Time" value={DEPARTURE_TIME} />
            <Dt label="Departure City" value={DEPARTURE_CITY} />
            <Dt label="Departure Airport" value={DEPARTURE_AIRPORT} />
            <Dt label="Preferred Departure Airline" value={PREFERRED_DEPARTURE_AIRLINE} />
            <Dt
              label="Preferred Departure Flight Number"
              value={PREFERRED_DEPARTURE_FLIGHT_NUMBER}
            />
            <Dt label="Return Date" value={RETURN_DATE} />
            <Dt label="Return Time" value={RETURN_TIME} />
            <Dt label="Preferred Return Airline" value={PREFERRED_RETURN_AIRLINE} />
            <Dt label="Preferred Return Flight Number" value={PREFERRED_RETURN_FLIGHT_NUMBER} />
            <Dt label="Additional Requests" value={ADDITIONAL_REQUESTS} />
            <Dt
              label="Needs Ground Transportation"
              value={NEED_GROUND_TRANSPORTATION ? 'Yes' : 'No'}
            />
            <Dt label="Preferred Airport" value={PREFERRED_AIRPORT} truthy />
            <Dt label="TSA PreCheck / Global Entry / Clear" value={TSA_PRECHECK} truthy />
          </Section>
          <Section name="airline" title="Airline Rewards Program(s)">
            <Dt label="Air Canada Aeroplan" value={AIR_CANADA_AEROPLAN} truthy />
            <Dt label="American Advantage" value={AMERICAN_ADVANTAGE} truthy />
            <Dt label="Delta SkyMiles" value={DELTA_SKYMILES} truthy />
            <Dt label="JetBlue TrueBlue" value={JET_BLUE_TRUE_BLUE} truthy />
            <Dt label="Southwest Rapid Rewards" value={SOUTHWEST_RAPID_REWARDS} truthy />
            <Dt label="United Mileage Plus" value={UNITED_MILEAGE_PLUS} truthy />
            <Dt label="Virgin America Elevate" value={VIRGIN_AMERICA_ELEVATE} truthy />
            <Dt label="WestJet Rewards" value={WEST_JET_REWARDS} truthy />
            <Dt label="Other Airline Rewards" value={OTHER_AIRLINE_REWARDS} truthy />
          </Section>
          <Section name="special" title="Special Requests">
            <Dt label="Seating Preference" value={SEATING_PREFERENCE} array />
            <Dt label="ADA Needs" value={ADA_NEEDS} truthy />
            <Dt label="Additional Info" value={ADDITIONAL_INFO} truthy />
            <Dt label="Dietary Needs" value={DIETARY_NEEDS} truthy />
          </Section>
        </Box>
      </Box>

      <ItrsTravelConfirmSection theme={theme} travelConfirmation={tc} travelRequestId={item.id} />
      <Box sx={{ minHeight: 24 }}></Box>
    </CmsPage>
  );
};

export default ItrsTravelConfirmationPage;
