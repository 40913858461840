import React from 'react';
import FormInput from '../../../shared/form/components/FormInput';
import FormDate from '../../../shared/form/components/FormDate';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Heading = ({ children, subheading = false, sx = {}, ...rest }) => (
  <Typography
    variant={subheading ? 'subtitle1' : 'h6'}
    sx={() => ({
      color: 'black',
      fontSize: '18px',
      fontWeight: 'bold',
      padding: 0,
      ...sx,
    })}
    {...rest}
  >
    {children}
  </Typography>
);

const inputPropsConfig = {
  variant: 'outlined',
  inputProps: {
    min: '0',
    sx: {
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        background: 'transparent',
        border: 'none',
        opacity: 1,
        display: 'block',
      },
      MozAppearance: 'textfield',
    },
  },
};

const ItrsTravelSegmentForm = ({ prefix, form, heading }) => {
  return (
    <Box>
      <Heading gutterBottom>{heading}</Heading>
      <div style={{ display: 'flex', alignItems: 'end' }}>
        <FormInput
          label="Airport"
          fieldName={prefix + 'Airport'}
          fieldWidth="short"
          formContext={form}
          type="text"
          {...inputPropsConfig}
          formFieldOptions={{
            required: true,
            validate: (v) => {
              if (v.length <= 0) return 'Must not be empty';
              return true;
            },
          }}
          onBlur={() => form.trigger('travelToAirport')}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'end' }}>
        <FormInput
          label="Airline"
          fieldName={prefix + 'Airline'}
          fieldWidth="short"
          formContext={form}
          type="text"
          {...inputPropsConfig}
          formFieldOptions={{
            required: true,
            validate: (v) => {
              if (v.length <= 0) return 'Must not be empty';
              return true;
            },
          }}
          onBlur={() => form.trigger('travelToAirline')}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'end' }}>
        <FormInput
          label="Flight Number"
          fieldName={prefix + 'Flight'}
          fieldWidth="short"
          formContext={form}
          type="text"
          {...inputPropsConfig}
          formFieldOptions={{
            required: true,
            validate: (v) => {
              if (v.length <= 0) return 'Must not be empty';
              return true;
            },
          }}
          onBlur={() => form.trigger('travelToFlight')}
        />
      </div>

      {prefix === 'departFrom' && (
        <div style={{ display: 'flex', alignItems: 'end' }}>
          <FormDate
            label={`Departure Date/Time`}
            fieldName={prefix + 'DepartDate'}
            fieldWidth="short"
            formContext={form}
            variant="outlined"
            formFieldOptions={{
              required: true,
            }}
          />
        </div>
      )}

      {prefix === 'travelTo' && (
        <div style={{ display: 'flex', alignItems: 'end' }}>
          <FormDate
            label={`Arrival Date/Time`}
            fieldName={prefix + 'ArrivalDate'}
            fieldWidth="short"
            formContext={form}
            variant="outlined"
            formFieldOptions={{
              required: true,
            }}
          />
        </div>
      )}
      <div style={{ display: 'flex', alignItems: 'end' }}>
        <FormInput
          label="Confirmation Number"
          fieldName={prefix + 'Confirmation'}
          fieldWidth="short"
          formContext={form}
          type="text"
          {...inputPropsConfig}
          formFieldOptions={{
            required: true,
            validate: (v) => {
              if (v.length <= 0) return 'Must not be empty';
              return true;
            },
          }}
          onBlur={() => form.trigger('travelToConfirmation')}
        />
      </div>
    </Box>
  );
};

export default ItrsTravelSegmentForm;
