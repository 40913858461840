import createPageConstants from '../shared/util/createPageConstants';
const pluginId = 'qrscanner';
// export const QrScannerConstants = {
//   defaultDefault: 'qrscannersListPage',
//   defaultPageName: 'qrscannersListPage',
//   editor: 'qrScannerEditorPage',
//   editorPageName: 'qrScannerEditorPage',
//   exportPageName: 'qrScannerLogExport',
//   pluginId,
//   targetEditorPageName: 'qrScannerTargetEditor',
//   targetGroupEditor: 'qrScannerTargetGroupEditor',
//   targetGroupEditorPageName: 'qrScannerTargetGroups',
//   targetPage: 'qrScannerTargets',
// };
export const QrScannerConstants = createPageConstants(pluginId, 'QR Scanner', {
  // defaultDefault: 'default',
  defaultDefault: 'qrscannersListPage',
  editor: 'qrScannerEditorPage',
  export: 'qrScannerLogExport',
  pluginId,
  targetEditor: 'qrScannerTargetEditor',
  targetGroupEditor: 'qrScannerTargetGroupEditor',
  targetGroups: 'qrScannerTargetGroups',
  targets: 'targets',
});

export const EMPTY_QRSCANNER = {
  name: '',
  type: '',
};
export const EMPTY_SCAN_TARGET = {
  name: '',
  group: '',
  value: '',
};
export const EMPTY_SCAN_TARGET_GROUP = {
  name: '',
};
export const scannerTypeObject = {
  urlBrowserScanner: 'URL Browser Scanner',
  recordingScanner: 'Recording Scanner',
};
