import { Box, Divider } from '@mui/material';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useMapData from '../hooks/useMapData';
import GoogleMapComponent from './GoogleMapComponent';
import KmlImports from './KmlImports';
import MapsTable from './MapsTable';
import GenericTextInput from '../../shared/genericInputs/GenericTextInput';

const MapsDetailPage = ({ mapName = 'combined-map', mapId }) => {
  const mapRef = useRef(null);
  const [saveReady, setSaveReady] = useState(false);
  const {
    state,
    originalState,
    setState,
    tableConfigs,
    resetState,
    handleSave,
    info,
    markerInfo,
    hasCenterChanged,
    hasZoomChanged,
  } = useMapData(mapId);

  useEffect(() => {
    if (mapId === 'new' && !state.title) {
      setState({ ...state, title: 'New Map' });
    }
  }, [mapId]);

  const handleInputChange = (e) => {
    setState({ ...state, title: e.target.value });
  };

  return (
    <CmsPage title="Maps">
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '1rem;',
            marginLeft: '-7px',
            paddingLeft: 0,
          }}
        >
          <GenericTextInput
            label="Map Title:"
            value={state.title || ''}
            autoFocus
            fieldWidth="normal"
            onChange={handleInputChange}
          />
        </Box>

        <GoogleMapComponent
          mapRef={mapRef}
          setState={setState}
          state={state}
          originalState={originalState}
          mapName={mapName}
          mapId={mapId}
          hasCenterChanged={hasCenterChanged}
          hasZoomChanged={hasZoomChanged}
        />
        {tableConfigs.map((tbl, index) => (
          <Fragment key={`${index}-${tbl.title}`}>
            {index === 1 && (
              <Box>
                <Divider sx={{ borderBottomWidth: 2, my: '2rem', color: 'black' }} />
                <KmlImports
                  mapRef={mapRef}
                  state={state}
                  setState={setState}
                  mapName={mapName}
                  setSaveReady={setSaveReady}
                />
              </Box>
            )}
            <MapsTable
              {...tbl}
              state={state}
              setState={setState}
              mapName={mapName}
              info={info}
              markerInfo={markerInfo}
              mapId={mapId}
            />
          </Fragment>
        ))}
        <Box display="flex" justifyContent="flex-end" flexGrow={1}>
          <Button variant="primary" onClick={handleSave} disabled={!saveReady || !state.title}>
            Save
          </Button>
          <Button variant="cancel" onClick={resetState}>
            Cancel
          </Button>
        </Box>
      </Box>
    </CmsPage>
  );
};

export default MapsDetailPage;
