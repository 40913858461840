import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, FormControl } from 'react-bootstrap';
import log from '../../logger';
import { upsertKmlFile } from '../api/mapsApi';
import { parseKmlData } from '../helpers';
const KmlImports = ({ mapName, state, setState, setSaveReady }) => {
  const [hasSetMethod, setHasSetMethod] = useState(false);
  const [importMethod, setImportMethod] = useState('Link');
  const [kmlUrl, setKmlUrl] = useState('');
  const fileInputRef = useRef(null);
  const handleSelectChange = (e) => {
    if (e.target.value !== importMethod) {
      setHasSetMethod(true);
      setState((st) => ({
        ...st,
        kmlUrl: '',
        kmlFileId: '',
        kmlRegions: [],
        kmlMarkers: [],
      }));
    }
    setImportMethod(e.target.value);
  };
  useEffect(() => {
    if (!hasSetMethod && (state.kmlUrl || state.kmlFileId)) {
      setHasSetMethod(true);
      setImportMethod(state.kmlUrl ? 'Link' : 'Upload');
    }
  }, [hasSetMethod, setHasSetMethod, state.kmlFileId, state.kmlUrl]);

  useEffect(() => {
    if (!kmlUrl && state.kmlUrl) {
      setKmlUrl(state.kmlUrl);
    }
  }, [state.kmlUrl]);

  useEffect(() => {
    switch (importMethod) {
      case 'Link': {
        const ready = kmlUrl.length > 0 && kmlUrl === state.kmlUrl;
        setSaveReady(ready);
        break;
      }
      case 'Upload': {
        setSaveReady(!!state.kmlFileId);
        break;
      }
      case 'None': {
        setSaveReady(true);
        break;
      }
      default: {
        setSaveReady(false);
      }
    }
  }, [importMethod, kmlUrl, state.kmlFileId, setSaveReady, state.kmlUrl]);

  const handleUrlChange = (e) => {
    setKmlUrl(e.target.value);
  };
  const addKmlFromUrl = () => {
    setState((st) => ({ ...st, kmlUrl }));
  };
  const handleChooseFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    const data = await file.text();
    const { kmlFileId, link } = await upsertKmlFile(mapName, file);
    await new Promise((r) => setTimeout(r, 2000));
    if (file) {
      try {
        const { kmlMarkers, kmlRegions } = parseKmlData(data, state);
        setState((st) => ({ ...st, kmlFileId, kmlUrl: link, kmlRegions, kmlMarkers }));
      } catch (err) {
        log.error('Error reading file:', err);
      }
    }
  };

  return (
    <div>
      <Form.Group controlId="kmlImportMethod" style={{ marginBottom: '1rem' }}>
        <Form.Label>KML Import Method</Form.Label>
        <Grid container>
          <Grid item xs={3}>
            <Form.Control
              as="select"
              value={importMethod}
              onChange={handleSelectChange}
              className="form-control"
            >
              <option value="None">None</option>
              <option value="Link">Link</option>
              <option value="Upload">Upload</option>
            </Form.Control>
          </Grid>
        </Grid>
      </Form.Group>

      {importMethod === 'Link' && (
        <Box sx={{ display: 'flex' }}>
          <Button variant="primary" style={{ marginRight: '1rem' }} onClick={addKmlFromUrl}>
            Add
          </Button>
          <input
            type={'text'}
            placeholder="URL"
            className="form-control"
            value={kmlUrl}
            onChange={handleUrlChange}
          />
        </Box>
      )}

      {importMethod === 'Upload' && (
        <Box sx={{ display: 'flex' }}>
          <Button variant="primary" onClick={handleChooseFileClick} style={{ marginRight: '1rem' }}>
            Choose File
          </Button>
          <FormControl
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            style={{ display: 'none' }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Form.Text className="text-muted">(File must be in .kml format.)</Form.Text>
            <Typography>{state.kmlFileId}</Typography>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default KmlImports;
