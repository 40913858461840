import { compact, get, isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { errorGrowl, successGrowl } from '../../../store/rootThunks';
import { navigateToParentPage, navigateToPluginPage } from '../../../store/rootReducer';
const useItemEditActions = (
  saveItem,
  form,
  pageConstants,
  argId = undefined,
  idKeyToNaviagte = '',
  customProps = {}
) => {
  const dispatch = useDispatch();
  const { defaultPageId, defaultPageName, typeName, pluginId, editorPageId } = pageConstants;
  const {
    preventNavigateBackOnSave = false,
    refreshPageParams = {},
    cancelOverride,
    saveNavigateOverride,
  } = customProps;
  const shouldRefresh = !isEmpty(refreshPageParams);
  const { formState, handleSubmit } = form;
  const { isDirty, isValid } = formState;
  const navigateBackArgs = argId ? [argId] : [];
  const idIsNew = argId === 'new';
  const navigateBack = useCallback(
    (overRideId = '') => {
      dispatch(
        navigateToParentPage([
          pluginId,
          defaultPageName,
          defaultPageId,
          ...(overRideId ? [overRideId] : navigateBackArgs),
        ])
      );
    },
    [dispatch, pluginId, defaultPageName, defaultPageId, navigateBackArgs]
  );
  const navigateRefresh = useCallback(
    (item) => {
      const {
        pluginId: plugId,
        pageName,
        pageId,
        customItemId = 'id',
        hardRefresh = false,
      } = refreshPageParams;
      if (hardRefresh) {
        window.location.reload();
        return;
      }
      const itemId = get(item, customItemId);
      dispatch(navigateToPluginPage(compact([plugId, pageName, pageId, itemId])));
    },
    [dispatch, refreshPageParams]
  );

  const navigateToMain = useCallback(() => {
    dispatch(navigateToPluginPage([pluginId, 'default']));
  }, [dispatch, pluginId]);
  const save = useCallback(
    async (draftItem) => {
      try {
        const savedItem = await saveItem(draftItem);
        let targetGrowlId = editorPageId;
        if (saveNavigateOverride && typeof saveNavigateOverride === 'function') {
          saveNavigateOverride(savedItem);
        } else if (preventNavigateBackOnSave !== true && !shouldRefresh) {
          targetGrowlId = defaultPageId;
          navigateBack(idKeyToNaviagte ? get(savedItem, idKeyToNaviagte) : '');
        } else if (shouldRefresh) {
          navigateRefresh(savedItem);
        }
        dispatch(
          successGrowl({
            groupId: targetGrowlId,
            props: `The ${typeName} has been successfully updated!`,
          })
        );
        return savedItem;
      } catch (e) {
        dispatch(errorGrowl({ groupId: editorPageId, props: e.message }));
      }
    },
    [
      defaultPageId,
      dispatch,
      editorPageId,
      idIsNew,
      navigateBack,
      navigateRefresh,
      navigateToMain,
      preventNavigateBackOnSave,
      saveItem,
      shouldRefresh,
      saveNavigateOverride,
      idKeyToNaviagte,
    ]
  );

  const cancel = useCallback(async () => {
    if (typeof cancelOverride === 'function') {
      cancelOverride();
      return;
    }
    return idIsNew ? navigateToMain() : navigateBack();
  }, [navigateBack, navigateToMain, idIsNew, cancelOverride]);

  return useMemo(() => {
    const canSave = isDirty && isValid;
    return [
      {
        label: 'Save Changes',
        disabled: !canSave,
        onClick: handleSubmit((item) => {
          save(item, preventNavigateBackOnSave !== true);
        }),
      },
      {
        label: 'Cancel',
        disabled: false,
        variant: 'default',
        onClick: cancel,
      },
    ];
  }, [save, cancel, isDirty, preventNavigateBackOnSave, isValid]);
};

export default useItemEditActions;
