import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Wysiwyg from '../../shared/basicInputs/Wysiwyg';
const AppUserWysiwygModal = ({ handleUpdateItem, item, closeModal, open, title }) => {
  const [content, setContent] = useState(item?.html || '');

  const handleSave = async (e) => {
    e.preventDefault();
    await handleUpdateItem(content);
    closeModal();
  };

  useEffect(() => {
    setContent(item?.html || '');
  }, [item?.id]);

  return (
    <Modal show={open} onHide={closeModal} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSave}>
        <Modal.Body>
          <Wysiwyg label="Content" value={content} setValue={setContent} skipFinalization={true} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" variant="primary">
            Save and Close
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AppUserWysiwygModal;
