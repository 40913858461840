import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import log from '../../logger';
import useArray from '../../shared/util/hooks/useArray';
import { navigateToPluginPage } from '../../store/rootReducer';
import { errorGrowl } from '../../store/rootThunks';
import pushNotificationApi, { cancelPushNotification } from '../api/pushNotificationApi';

const { removeRecord, loadRecord } = pushNotificationApi;

export default function usePushNotificationActions(pageConstants, hiddenButton) {
  const [autoRefresh, setAutoRefresh] = useState(true);
  const dispatch = useDispatch();
  const nav = useCallback((...params) => dispatch(navigateToPluginPage(...params)), [dispatch]);
  const err = useCallback(
    (e, props) => {
      log.error(e);
      dispatch(errorGrowl({ groupId: pageConstants.defaultPageId, props }));
    },
    [dispatch, pageConstants.defaultPageId]
  );

  const refresh = useMemo(
    () => ({
      visible: !autoRefresh,
      label: autoRefresh ? 'Hide me' : 'Refresh',
      onClick: async ({ reload }) => {
        try {
          await reload();
        } catch (e) {
          err(e, 'Failed to refresh.');
        }
      },
    }),
    [autoRefresh, err]
  );

  const autoRefreshButton = useMemo(
    () => ({
      label: autoRefresh ? 'Auto Refresh is ON' : 'Auto Refresh is OFF',
      onClick: async () => setAutoRefresh((current) => !current),
    }),
    [autoRefresh]
  );

  const { typeName, pluginId, defaultPageId, editorPageName } = pageConstants;

  const addOverride = useMemo(
    () => ({
      label: '+ Schedule Notification',
      onClick: async () => {
        try {
          const newPushNotification = await loadRecord('new');
          nav([pluginId, editorPageName, newPushNotification.id || 'new']);
        } catch (e) {
          err(e, 'Failed to add.');
        }
      },
    }),
    [typeName, pluginId, defaultPageId, editorPageName, nav, err]
  );

  const view = useMemo(
    () => ({
      label: 'View',
      onClick: async (item) => {
        try {
          const newPushNotification = await loadRecord(item.id);
          nav([pluginId, editorPageName, newPushNotification.id || 'new']);
        } catch (e) {
          err(e, 'Failed to view.');
        }
      },
    }),
    [typeName, pluginId, defaultPageId, editorPageName, nav, err]
  );

  const cancel = useMemo(
    () => ({
      label: 'Cancel',
      disabled: ({ status = '' }) => status.toLowerCase() !== 'scheduled',
      onClick: async (item, { reload }) => {
        try {
          await cancelPushNotification(item.id);
          await reload();
        } catch (e) {
          err(e, 'Failed to cancel.');
        }
      },
    }),
    [typeName, pluginId, defaultPageId, editorPageName, err]
  );

  const edit = useMemo(
    () => ({
      label: 'Edit',
      disabled: ({ status = '', sendTimestamp }) => {
        if (status.toLowerCase() !== 'scheduled') return true;
        const sendTime = new Date(sendTimestamp);
        const now = new Date();
        const tenMinutesFromNow = new Date(now.getTime() + 10 * 60 * 1000);
        return sendTime <= tenMinutesFromNow;
      },
      onClick: async ({ id }) => {
        try {
          nav([pluginId, editorPageName, id]);
        } catch (e) {
          err(e, 'Failed to edit.');
        }
      },
    }),
    [pluginId, editorPageName, err, nav]
  );

  const copy = useMemo(
    () => ({
      label: 'Copy',
      onClick: async (item) => {
        try {
          nav([pluginId, editorPageName, 'new', true, item.id]);
        } catch (e) {
          err(e, 'Failed to copy.');
        }
      },
    }),
    [pluginId, editorPageName, nav, err]
  );

  const archive = useMemo(
    () => ({
      label: (item) => (item.isArchived ? 'Unarchive' : 'Archive'),
      onClick: async (item, { reload }) => {
        try {
          await pushNotificationApi.editPushNotification(item.id, { isArchived: !item.isArchived });
          await reload();
        } catch (e) {
          err(e, 'Failed to archive/unarchive.');
        }
      },
    }),
    [err]
  );

  const del = useMemo(
    () => ({
      label: 'Delete',
      onClick: async (item, { reload }) => {
        try {
          await removeRecord(item);
          await reload();
        } catch (e) {
          err(e, 'Failed to delete.');
        }
      },
    }),
    [err]
  );

  return {
    toolbarActions: useArray(addOverride, refresh, autoRefreshButton, hiddenButton),
    itemActions: useArray(view, edit, copy, archive, cancel, del),
    autoRefresh,
  };
}
