import React from 'react';
import RadioButtonGroup from '../../../../shared/form/components/FormRadioButtonGroup';

const SessionStatus = ({ form, sessionId }) => {
  return (
    <RadioButtonGroup
      labelWeight={500}
      labelColor="#949494"
      name="status"
      type="radio"
      form={form}
      label="Session Status"
      options={[
        { value: 'draft', label: 'Draft' },
        { value: 'enrolling', label: 'Enrolling' },
        { value: 'active', label: 'Active' },
        { value: 'cut-off reached', label: 'Cut-Off Reached' },
        { value: 'complete', label: 'Complete', disabled: sessionId === 'new' },
      ]}
      validation={{ required: 'Status is required.' }}
    />
  );
};

export default SessionStatus;
