import { TextField } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import useFieldProps from '../hooks/useFieldProps';
import FieldWrapper from './FieldWrapper';
import FormFieldError from './FormFieldError';

const FormDate = (props) => {
  const {
    label,
    fieldName,
    isDateOnly = false,
    placeholder,
    formFieldOptions = {},
    formContext,
    fieldWidth,
    defaultStateValue = '',
    localTime = false,
  } = props;
  const [inputValue, setInputValue] = useState(defaultStateValue || '');
  const {
    width,
    fieldError,
    value,
    isRequired,
    inputAttributes,
    variant = 'standard',
  } = useFieldProps(props, true);
  const { register, setValue } = formContext;
  const { onBlur, name } = register(fieldName, {
    validate: () => true,
    ...formFieldOptions,
  });

  useEffect(() => {
    if (value !== undefined && value !== null && value !== '') {
      setInputValue(moment(value).format(`YYYY-MM-DD${isDateOnly ? '' : 'THH:mm'}`));
    } else {
      setInputValue('');
    }
  }, [value, isDateOnly]);

  const handleChange = async (e) => {
    let v = e.target.value;
    setInputValue(v);
    if (
      v &&
      (isDateOnly ||
        localTime ||
        inputAttributes.type === 'date' ||
        inputAttributes.type === 'datetime-local')
    ) {
      const localMoment = moment(v, `YYYY-MM-DD${isDateOnly ? '' : 'THH:mm'}`);
      v = localMoment.isValid() ? localMoment.toISOString() : '';
    }
    await setValue(fieldName, v, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  return (
    <FieldWrapper {...props}>
      <TextField
        label={label}
        variant={variant}
        value={inputValue}
        type={isDateOnly ? 'date' : 'datetime-local'}
        fullWidth={fieldWidth === 'full'}
        placeholder={placeholder}
        error={!!fieldError}
        helperText={fieldError ? <FormFieldError error={fieldError} /> : ''}
        onBlur={onBlur}
        name={name}
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
        {...inputAttributes}
        sx={{ width }}
        required={isRequired}
      />
    </FieldWrapper>
  );
};

export default FormDate;
