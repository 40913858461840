import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import log from '../../logger';
import { navigateToPluginPage } from '../../store/rootReducer';
import { errorGrowl, successGrowl } from '../../store/rootThunks';
import scanTargetsApi from '../api/scanTargetsApi';
import scanTargetGroupsApi from '../api/scanTargetGroupsApi';

export default function useQrTargetGroupActions(pageConstants) {
  const dispatch = useDispatch();

  const add = useMemo(
    () => ({
      label: `+ New Target Group`,
      onClick: async () => {
        try {
          const newQrScannerTarget = await scanTargetGroupsApi.loadRecord('new');
          dispatch(
            navigateToPluginPage([
              pageConstants.pluginId,
              pageConstants.targetGroupEditorPageName,
              newQrScannerTarget.id || 'new',
            ])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: pageConstants.defaultPageId, props: 'Failed to add.' }));
        }
      },
    }),
    [dispatch, pageConstants]
  );
  const remove = useMemo(
    () => ({
      label: 'Remove',
      icon: 'fa-solid fa-trash',
      onClick: async (context, { reload }) => {
        try {
          const confirmResponse = await confirm({
            title: 'Delete QrScanner',
            message: `Are you sure you want to delete '${context.name}'?`,
          });

          if (confirmResponse) {
            await scanTargetsApi.removeRecord(context.id);
            await reload();
            dispatch(
              successGrowl({
                groupId: pageConstants.defaultPageId,
                props: 'Successfully removed qrscanner.',
              })
            );
          }
        } catch (e) {
          log.error(e);
          dispatch(
            errorGrowl({
              groupId: pageConstants.defaultPageId,
              props: 'Failed to update qrscanner.',
            })
          );
        }
      },
    }),
    [dispatch, pageConstants.pluginId, pageConstants.defaultPageId, confirm]
  );

  const createGroup = useMemo(
    () => (form) => ({
      label: `Create Target Group`,
      onClick: async () => {
        try {
          const name = form.getValues('name');
          await scanTargetGroupsApi.saveGroup({ name });
          dispatch(
            navigateToPluginPage([pageConstants.pluginId, pageConstants.targetGroupsPageName])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: pageConstants.defaultPageId, props: 'Failed to add.' }));
        }
      },
    }),
    [pageConstants, dispatch]
  );
  const cancel = useMemo(
    () => ({
      label: `Cancel`,
      variant: 'default',
      onClick: async () => {
        try {
          dispatch(
            navigateToPluginPage([pageConstants.pluginId, pageConstants.targetGroupsPageName])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: pageConstants.defaultPageId, props: 'Failed to add.' }));
        }
      },
    }),
    [pageConstants, dispatch]
  );
  const back = () => {
    dispatch(navigateToPluginPage([pageConstants.pluginId, pageConstants.targetsPageName]));
  };
  return {
    add,
    remove,
    createGroup,
    cancel,
    back,
  };
}
