import React from 'react';
import { Box, Modal, Typography, Button } from '@mui/material';

const style = {
  fontFamily: 'Inter, sans-serif',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  minHeight: 357,
  bgcolor: 'background.paper',
  border: '1px solid #909090',
  borderTop: 0,
  borderBottom: 0,
  boxSizing: 'border-box',
  //p: 4,
  display: 'flex',
  flexDirection: 'column',
};

const headerStyle = {
  borderBottom: '1px solid #E0E0E0',
  pt: 2,
  pb: 1,
  px: 2,
  mb: 2,
  fontFamily: 'Open Sans, sans-serif',
  fontWeight: 500,
  fontSize: 'calc(1.275rem + .3vw)',
  lineHeight: 'var(--bs-modal-title-line-height, 1.2)',
};

const bodyStyle = {
  fontFamily: 'Inter, sans-serif',
  px: 2,
};

const sectionHeaderStyle = {
  fontFamily: 'Inter, sans-serif',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24.5px',
  color: '#3A3A3A',
};

const sectionStyle = {
  pb: 2,
  pt: 1,
};

const contentStyle = {
  fontFamily: 'Inter, sans-serif',
  flex: 1,
  overflowY: 'auto',
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: '22.75px',
};

const deletedStyle = {
  fontFamily: 'Inter, sans-serif',
  flex: 1,
  overflowY: 'auto',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '24.5px',
  color: '#939090',
};

const updateStyle = {
  fontFamily: 'Inter, sans-serif',
  pb: 2,
  pt: 1,
  display: 'flex',
  justifyContent: 'flex-start',
};

const footerStyle = {
  fontFamily: 'Inter, sans-serif',
  display: 'flex',
  justifyContent: 'flex-start',
  gap: 2,
  p: 2,
  borderTop: '1px solid #E0E0E0',
  mt: 'auto',
};

const linkStyle = {
  fontFamily: 'Inter, sans-serif',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '24.5px',
  color: '#266EAB',
};

const CohortRemoveModal = ({
  open,
  onClose,
  cohort,
  collections,
  audiences,
  remove,
  update,
  rest,
}) => {
  const collectionsClear =
    !collections ||
    (!collections.publishedRefs.length &&
      !collections.unpublishedRefs.length &&
      !collections.deletedRefs.length);
  const audiencesClear =
    !audiences ||
    (!audiences.publishedTARefs.length &&
      !audiences.unpublishedTARefs.length &&
      !audiences.deletedTARefs.length);
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="cohort-remove-modal"
      aria-describedby="modal-to-remove-or-view-cohort-usage"
    >
      <Box sx={style}>
        <Box>
          <Typography sx={headerStyle}>Remove Cohort</Typography>
        </Box>

        <Box sx={bodyStyle}>
          <Box>
            <Typography sx={contentStyle} variant="body1" gutterBottom>
              Remove all references to this cohort from the linked pages below to continue.
            </Typography>
          </Box>

          <Box sx={sectionStyle}>
            <Typography variant="body1" gutterBottom sx={sectionHeaderStyle}>
              Collection Item Links
            </Typography>
            {collectionsClear && (
              <Typography sx={deletedStyle} variant="body1" gutterBottom>
                No pages reference this cohort
              </Typography>
            )}
            {!collectionsClear &&
              collections?.publishedRefs?.length > 0 &&
              collections?.publishedRefs?.map((collectionRef) => (
                <Typography
                  key={collectionRef?.collectionName + collectionRef?.collectionItemLabel}
                  component="a"
                  href={collectionRef?.link}
                  target="_new"
                  sx={linkStyle}
                >
                  {collectionRef?.collectionName}:{collectionRef?.collectionItemLabel}
                </Typography>
              ))}
            {!collectionsClear &&
              collections?.unpublishedRefs?.length > 0 &&
              collections?.unpublishedRefs?.map((collectionRef) => (
                <Typography
                  key={collectionRef?.collectionName + collectionRef?.collectionItemLabel}
                  component="a"
                  href={collectionRef?.link}
                  target="_new"
                  sx={linkStyle}
                >
                  {collectionRef?.collectionName}:{collectionRef?.collectionItemLabel}
                </Typography>
              ))}
            {!collectionsClear &&
              collections?.deletedRefs?.length > 0 &&
              collections?.deletedRefs?.map((collectionRef) => (
                <Typography
                  key={collectionRef?.collectionName + collectionRef?.collectionItemLabel}
                  component="a"
                  href={collectionRef?.link}
                  target="_new"
                  sx={linkStyle}
                >
                  {collectionRef?.collectionName}:{collectionRef?.collectionItemLabel}
                </Typography>
              ))}
          </Box>

          <Box sx={sectionStyle}>
            <Typography variant="body1" gutterBottom sx={sectionHeaderStyle}>
              Saved Audience Links
            </Typography>
            {audiencesClear && (
              <Typography sx={deletedStyle} variant="body1" gutterBottom>
                No pages reference this cohort
              </Typography>
            )}
            {!audiencesClear &&
              audiences?.publishedTARefs?.length > 0 &&
              audiences?.publishedTARefs?.map((audienceRef) => (
                <Typography
                  key={audienceRef?.audienceName}
                  component="a"
                  href={audienceRef?.link}
                  target="_new"
                  sx={linkStyle}
                >
                  {audienceRef?.audienceName}
                </Typography>
              ))}
            {!audiencesClear &&
              audiences?.unpublishedTARefs?.length > 0 &&
              audiences?.unpublishedTARefs?.map((audienceRef) => (
                <Typography
                  key={audienceRef?.audienceName}
                  component="a"
                  href={audienceRef?.link}
                  target="_new"
                  sx={linkStyle}
                >
                  {audienceRef?.audienceName}
                </Typography>
              ))}
            {!audiencesClear &&
              audiences?.deletedTARefs?.length > 0 &&
              audiences?.deletedTARefs?.map((audienceRef) => (
                <Typography
                  key={audienceRef?.audienceName}
                  component="a"
                  href={audienceRef?.link}
                  target="_new"
                  sx={linkStyle}
                >
                  {audienceRef?.audienceName}
                </Typography>
              ))}
          </Box>

          <Box sx={updateStyle}>
            <Button
              id="update-button"
              variant="outlined"
              onClick={() => update(cohort)}
              sx={{
                textTransform: 'none',
                border: '1px solid #484646',
                color: '#484646',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '24.5px',
              }}
            >
              Update list
              <span style={{ paddingLeft: '3px' }} className={'fa-solid fa-rotate-right'} />
            </Button>
          </Box>
        </Box>

        <Box sx={footerStyle}>
          <Button
            variant="contained"
            disabled={!audiencesClear || !collectionsClear}
            onClick={() => {
              remove(cohort, rest);
              onClose();
            }}
            sx={{
              mr: 1,
              bgcolor: '#345784',
              '&:hover': {
                bgcolor: '#2a466a',
              },
              textTransform: 'none',
            }}
          >
            Delete
          </Button>
          <Button
            variant="text"
            onClick={onClose}
            sx={{
              textTransform: 'none',
              color: '#14191E',
              fontFamily: 'Inter, sans-serif',
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CohortRemoveModal;
