import { toNumber } from 'lodash';
import moment from 'moment';
import React from 'react';
import FormDateOnly from '../../../../shared/form/components/FormDateOnly';
import FormInput from '../../../../shared/form/components/FormInput';
import { inputPropsConfig } from '../index';
const getDateOnly = (date) => (date ? moment(date).startOf('day').toDate() : null);

const SessionDates = ({ form, parsedSessionDates = {}, sessionName }) => {
  const { trigger } = form;
  const { startDate, endDate } = parsedSessionDates || {};
  const start = getDateOnly(startDate);
  const end = getDateOnly(endDate);
  const today = moment().startOf('day').toDate();
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 30 }}>
        <div>
          <FormDateOnly
            label={`Start Date`}
            fieldName="startDate"
            formContext={form}
            fieldWidth="short"
            formFieldOptions={{
              required: 'Start date is required',
              validate: (startDateValue) => {
                if (!sessionName || !startDateValue) return true;
                if (!start) return 'Invalid start date';
                if (start < today) return 'Start date cannot be in the past';
                return true;
              },
            }}
          />
        </div>

        <div style={{ marginLeft: 20 }}>
          <FormDateOnly
            label={`End Date`}
            fieldName="endDate"
            fieldWidth="short"
            formContext={form}
            formFieldOptions={{
              required: 'End date is required',
              validate: (endDateValue) => {
                if (!endDateValue) return true;
                if (!end) return 'Invalid end date';
                if (start && end < start) return 'End date cannot be before the start date';
                return true;
              },
            }}
          />
        </div>
      </div>

      <div style={{ display: 'flex', alignItems: 'end' }}>
        <FormInput
          label="Enrollment Cut-Off"
          fieldName="cutoffDays"
          fieldWidth="short"
          formContext={form}
          type="number"
          {...inputPropsConfig}
          formFieldOptions={{
            required: 'Enrollment Cutoff is required',
            validate: (v) => {
              if (toNumber(v) < 0) return 'Minimum value is 0';
              return true;
            },
          }}
          onBlur={() => trigger('cutoffDays')}
        />
        <p style={{ marginLeft: 10, fontWeight: 500 }}>days before start date of session</p>
      </div>
    </>
  );
};

export default SessionDates;
