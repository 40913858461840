import axios from 'axios';
import log from '../../logger';
import courseApi from './itrsApi';

const { loadRecord: loadCourse } = courseApi;

const baseUrl = '/plugins/custom-plugins/iuoe/itrs/api';

const loadRecord = async (id) => {
  if (id === 'new') return { id: 'new', travelHorizons: true };

  try {
    const { data } = await axios.get(`${baseUrl}/getSession`, { params: { id } });
    return data.session;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load Session.');
  }
};

const removeRecord = async (id) => {
  try {
    await axios.post(`${baseUrl}/deleteSession`, {}, { params: { id } });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove Session.');
  }
};

const approveTrainingRequest = async (approvalId, status, denialReason = '') => {
  try {
    await axios.post(
      `${baseUrl}/updateApproval`,
      {},
      { params: { id: approvalId, status, denialReason } }
    );
  } catch (e) {
    log.error(e);
    throw new Error('Failed to Approve Request.');
  }
};

const loadRecords = async (params) => {
  try {
    const { courseId, sort, ...otherParams } = params;
    const { sortField, order } = sort;
    const sortReverse = sortField ? order === 'DESC' : undefined;
    const {
      data: { rows, count },
    } = await axios.get(`${baseUrl}/getSessionsByCourseId`, {
      params: { ...otherParams, courseId, sortField, sortReverse },
      timeout: 10_000,
    });

    return {
      records: rows,
      numRecords: count,
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load session');
  }
};

const saveRecord = async (session) => {
  if (!session.id) session.id = 'new';
  try {
    const { data } = await axios.post(`${baseUrl}/upsertSession`, session);
    return data.session;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save session.');
  }
};

const itrsApi = {
  loadRecord,
  loadRecords,
  saveRecord,
  removeRecord,
  approveTrainingRequest,
  loadCourse,
};

export const getSessionDetails = async (id, unionSearchField) => {
  try {
    const { data } = await axios.get(`${baseUrl}/getSessionDetails`, {
      params: { id, unionSearchField },
    });
    return data;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load Session.');
  }
};

export const cancelEnrollment = async (sessionId, enrollmentId, reason, note) => {
  try {
    const { data } = await axios.post(`${baseUrl}/cancelEnrollment`, {
      sessionId,
      enrollmentId,
      reason,
      note,
    });
    return data?.status === 'success';
  } catch (e) {
    log.error(e);
    throw new Error('Failed to cancel enrollment.');
  }
};

export const markCompletions = async (sessionId, enrollmentIds) => {
  try {
    const { data } = await axios.post(`${baseUrl}/markCompletions`, {
      sessionId,
      enrollmentIds,
    });
    return data;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to mark completions.');
  }
};

export default itrsApi;

export const getUsersToEnroll = async ({ searchValue, courseId, sessionId, type }) => {
  try {
    const { data } = await axios.get(`${baseUrl}/getUsersToEnroll`, {
      params: { searchValue, courseId, sessionId, type },
    });
    return data.users;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to find user by registration number.');
  }
};

export const cmsRegisterAnotherUserForSession = async (courseId, sessionId, userId, userType) => {
  try {
    const { data } = await axios.post(`${baseUrl}/cmsRegisterAnotherUserForSession`, {
      courseId,
      sessionId,
      userId,
      userType,
    });
    return data;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to register user for session.');
  }
};
