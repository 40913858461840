import React, { useMemo, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import _ from 'lodash';
export default function TanItemTable(props) {
  const {
    columns,
    rows,
    rowsPerPage = 10,
    isLoading,
    startPage,
    setRowCount,
    setPageIndex,
    totalRows,
    updateRequested,
    setSearchText,
    setSearchChanged,
    searchText,
  } = props;
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: startPage || 0,
    pageSize: rowsPerPage,
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const table = useReactTable({
    data: _.take(rows.slice(pageIndex * pageSize), pageSize),
    columns,
    pageCount: Math.ceil(totalRows / pageSize) || 1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
  });
  return (
    <div>
      <input
        value={searchText}
        style={{
          minWidth: '15em',
        }}
        onChange={(e) => {
          table.setPageIndex(0);
          setSearchText(e.target.value);
          setSearchChanged(true);
          updateRequested(true);
        }}
        placeholder="Filter by First or Last Name"
      />

      <div>
        {' '}
        {isLoading ? (
          'Loading...'
        ) : (
          <div>
            <table className="table table-striped">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th key={header.id} colSpan={header.colSpan} className="table-header">
                          {header.isPlaceholder ? null : (
                            <div>
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </div>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="flex items-center gap-2">
              <button
                className="border rounded p-1"
                onClick={() => {
                  table.setPageIndex(0);
                  setPageIndex(0);
                  updateRequested(true);
                }}
                disabled={!table.getCanPreviousPage()}
              >
                {'<<'}
              </button>
              <button
                className="border rounded p-1"
                onClick={() => {
                  table.previousPage();
                  setPageIndex(pageIndex - 1);
                  updateRequested(true);
                }}
                disabled={!table.getCanPreviousPage()}
              >
                {'<'}
              </button>
              <button
                className="border rounded p-1"
                onClick={() => {
                  table.nextPage();
                  setPageIndex(pageIndex + 1);
                  updateRequested(true);
                }}
                disabled={!table.getCanNextPage()}
              >
                {'>'}
              </button>
              <button
                className="border rounded p-1"
                onClick={() => {
                  table.setPageIndex(table.getPageCount() - 1);
                  setPageIndex(table.getPageCount() - 1);
                  updateRequested(true);
                }}
                disabled={!table.getCanNextPage()}
              >
                {'>>'}
              </button>
              <span className="flex items-center gap-1">
                <div>
                  <span>Page </span>
                  <strong>
                    {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
                  </strong>
                </div>
              </span>
              <span className="flex items-center gap-1">
                Go to page:
                <input
                  type="number"
                  defaultValue={table.getState().pagination.pageIndex + 1}
                  min={1}
                  max={table.getPageCount()}
                  onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    setPageIndex(page);
                    table.setPageIndex(page);
                    updateRequested(true);
                  }}
                  className="border p-1 rounded w-16"
                />
              </span>
              <select
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  setRowCount(e.target.value);
                  table.setPageSize(Number(e.target.value));
                  updateRequested(true);
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <div>{totalRows} Rows</div>
          </div>
        )}
      </div>
    </div>
  );
}
