import axios from 'axios';
import log from '../../logger';
import forEach from 'lodash/forEach';

const baseUrl = '/plugins/custom-plugins/iuoe/itrs/api';

const loadRecord = async (id) => {
  if (id === 'new') return { title: '', userGroups: [], id: 'new' }; // Assuming this is your EMPTY_MEET equivalent

  try {
    const { data } = await axios.get(`${baseUrl}/getCourse`, { params: { id } });
    if (data.course) {
      data.course.programs =
        typeof data.course.programs === 'string'
          ? JSON.parse(data.course.programs)
          : Array.isArray(data.course.programs)
          ? data.course.programs
          : [];

      data.course.approvers =
        typeof data.course.approvers === 'string'
          ? JSON.parse(data.course.approvers)
          : Array.isArray(data.course.approvers)
          ? data.course.approvers
          : [];
      data.course.ceu = data.course.ceu ? 'yes' : 'no';
      data.course.certificate = data.course.certificate ? 'yes' : 'no';
    }
    return data.course;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load course.');
  }
};

const removeRecord = async (id) => {
  try {
    await axios.post(`${baseUrl}/deleteCourse`, {}, { params: { id } });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove course.');
  }
};

const loadRecords = async (params) => {
  try {
    const { sort, ...otherParams } = params;
    const { sortField, order } = sort;
    const sortReverse = sortField ? order === 'DESC' : undefined;
    const {
      data: { rows, count },
    } = await axios.get(`${baseUrl}/getAllCourses`, {
      params: { ...otherParams, sortField, sortReverse },
      timeout: 10_000,
    });
    return {
      records: rows,
      numRecords: count,
      offset: otherParams.offset,
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load course');
  }
};

const saveRecord = async (course) => {
  try {
    //eslint-disable-next-line
    const formData = new FormData();
    course.ceu = course.ceu === 'yes';
    course.certificate = course.certificate === 'yes';

    forEach(course, (v, k) => {
      if (k === 'linkAssetUpload') formData.append(k, v);
      else if (v && typeof v === 'object') {
        const value = JSON.stringify(v);
        formData.append(k, value);
      } else {
        formData.append(k, v);
      }
    });

    const url =
      !course.id || course.id === 'new' ? `${baseUrl}/saveCourse` : `${baseUrl}/updateCourse`;
    const { data } = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 10_000,
    });
    return data.course;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save course.');
  }
};

const getCategories = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getCategories`);
    return data.categories;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to get categories');
  }
};

const getPrograms = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getPrograms`);
    return data.programs;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to get categories');
  }
};

const getBilling = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getBillingCategories`);
    return data.billingCategories;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to get categories');
  }
};

const getTrainingPrograms = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getTrainingLocations`);
    return data.trainingLocations;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to get categories');
  }
};

const getEquipment = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getEquipment`);
    return data.equipment;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to get categories');
  }
};

export const getRoomTypes = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getRoomTypes`);
    return data.roomTypes || [];
  } catch (e) {
    log.error(e);
    throw new Error('Failed to get categories');
  }
};

export const getReportingUrl = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getReportingUrl`);
    return data;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to get quicksight url');
  }
};

const itrsApi = {
  loadRecord,
  loadRecords,
  saveRecord,
  removeRecord,
  getCategories,
  getPrograms,
  getBilling,
  getTrainingPrograms,
  getEquipment,
  getReportingUrl,
};

export default itrsApi;
