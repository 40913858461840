import classNames from 'classnames';
import get from 'lodash/get';
import React, { useContext } from 'react';
import { ImageContext } from '../store';
import Cropper from './Cropper';

function ImageSelector(props) {
  const { useCropper, croppedRatio } = props;
  const {
    availableFilesMap,
    getInputProps,
    getRootProps,
    isDragAccept,
    isDragReject,
    isFocused,
    selectedFilePath,
    setCroppedImage,
  } = useContext(ImageContext);
  const { preview: src = '' } = get(availableFilesMap, `${selectedFilePath}`, {});

  const showCropper = useCropper && selectedFilePath !== 'DEFAULT_FILE';

  return (
    <div style={{ display: 'flex' }}>
      <div
        {...getRootProps({
          className: classNames('dz-container', {
            'dz-link': showCropper,
            'dz-link-img': !showCropper,
            'dz-focused': isFocused,
            'dz-accepted': isDragAccept,
            'dz-rejected': isDragReject,
          }),
        })}
      >
        {showCropper ? (
          <>
            <input {...getInputProps()} />
            <Cropper src={src} croppedRatio={croppedRatio} setCroppedImage={setCroppedImage} />
          </>
        ) : (
          <div>
            <img src={src} style={{ display: 'block', width: '100%', height: 'auto' }} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ImageSelector;
