import React from 'react';
import FormCheckBoxGroup from '../../../../shared/form/components/FormCheckBoxGroup';
import FormInput from '../../../../shared/form/components/FormInput';
import FormWysiwyg from '../../../../shared/form/components/FormWysiwyg';
import api from '../../../api/itrsApi';
import SessionMulti from './SessionMulti';

const SessionDetails = ({ form }) => {
  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <FormInput
          label="Session Name"
          fieldName="name"
          fieldWidth="normal"
          formContext={form}
          defaultValue=""
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />
      </div>

      <SessionMulti
        sx={{ mb: 4 }}
        fieldName="billingCategory"
        form={form}
        fieldWidth="normal"
        defaultValue="none"
        label="Billing Category"
        optionsLoader={api.getBilling}
      />

      <div style={{ width: '60%', marginBottom: 20 }}>
        <FormWysiwyg
          tag="textarea"
          label="Session Description"
          formContext={form}
          fieldName="description"
        />
      </div>

      <div>
        <SessionMulti
          sx={{ mb: 4 }}
          fieldName="trainingLocation"
          form={form}
          label="Training Location"
          optionsLoader={api.getTrainingPrograms}
          req
        />
      </div>

      <SessionMulti
        sx={{ mb: 4 }}
        fieldName="category"
        form={form}
        label="Category / Department"
        optionsLoader={api.getCategories}
        disabled={true}
        req
      />

      <SessionMulti
        fieldName="program"
        form={form}
        label="Program(s)"
        placeholder={'Select Program'}
        optionsLoader={api.getPrograms}
        isSingleSelect={false}
      />

      <div style={{ marginTop: 10, marginBottom: 20 }}>
        <FormCheckBoxGroup
          id="enrollment-approval"
          label="Enrollment Approval"
          name="approvers"
          form={form}
          formFieldOptions={{
            required: 'At least one approver is required.',
          }}
          options={[
            { label: 'Point of Contact', value: 'point-of-contact' },
            { label: 'Program Coordinator', value: 'program-coordinator' },
          ]}
        />
      </div>
    </>
  );
};

export default SessionDetails;
