import Divider from '@mui/material/Divider';
import React, { useEffect, useState } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import useLoadEditorItem from '../../shared/cmsPage/hooks/useLoadEditorItem';
import FormInput from '../../shared/form/components/FormInput';
import RadioButtonGroup from '../../shared/form/components/FormRadioButtonGroup';
import FormCheckBoxGroup from '../../shared/form/components/FormCheckBoxGroup';
import FormWysiwyg from '../../shared/form/components/FormWysiwyg';
import MultiSelect from '../../shared/form/components/MultiSelect';
import FormLinksImage from '../../shared/form/components/FormLinksImage';
import Select from '../../shared/form/components/Select';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import itrsApi from '../api/itrsApi';
import { ItrsCourseConstants } from '../definitions';
import { BackToCourses } from './BackButtons';

const ItrsCourseCreatePage = ({ courseId }) => {
  const { item, loadItem, saveItem, requestPending } = useItemPersistence(itrsApi);
  const form = useCmsForm(item);
  useLoadEditorItem(courseId, loadItem, () => {}, item, ItrsCourseConstants);
  const { watch, setValue } = form;
  const [categories, setCategories] = useState(null);
  const [programs, setPrograms] = useState(null);
  const [categoryLoaded, setCategoryLoaded] = useState(false);
  const [programLoaded, setProgramLoaded] = useState(false);

  useEffect(() => {
    const getCats = async () => itrsApi.getCategories();
    const getPrgs = async () => itrsApi.getPrograms();
    Promise.all([getCats(), getPrgs()]).then(([cats, prgs]) => {
      setCategories(cats);
      setPrograms(prgs);
    });
    setValue('status', 'draft');
    setValue('certificate', 'no');
    setCategoryLoaded(false);
    setProgramLoaded(false);
  }, [courseId]);

  useEffect(() => {
    if (
      !categoryLoaded &&
      item &&
      categories &&
      (!item.category || categories.find((cat) => cat.value === watch('category')))
    ) {
      setCategoryLoaded(true);
    }
  }, [categories?.length, item?.id]);

  useEffect(() => {
    if (
      !programLoaded &&
      item &&
      programs &&
      (!item.program || item.program?.length === watch('program')?.length)
    ) {
      setProgramLoaded(true);
    }
  }, [programs?.length, item?.id]);

  const actions = useItemEditActions(saveItem, form, {
    ...ItrsCourseConstants,
    defaultPageId: ItrsCourseConstants.coursePageId,
    defaultPageName: ItrsCourseConstants.coursePageId,
  });
  if (!categoryLoaded || !programLoaded || requestPending) {
    return <div>Loading...</div>;
  }

  const inputPropsConfig = {
    inputProps: {
      min: '0',
      sx: {
        '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
          background: 'transparent',
          border: 'none',
          opacity: 1,
        },
        MozAppearance: 'textfield',
      },
    },
  };

  return (
    <>
      <BackToCourses />
      <CmsPage title={item?.name || 'Add Course'}>
        <form role="form" className="no-left-padding">
          <div style={{ paddingBottom: 20, paddingTop: 10 }}>
            <RadioButtonGroup
              labelWeight={500}
              name="status"
              type="radio"
              form={form}
              label="Course Status"
              options={[
                { value: 'draft', label: 'Draft' },
                { value: 'active', label: 'Active' },
                { value: 'inactive', label: 'Inactive' },
              ]}
              formFieldOptions={{ required: 'Course status is required.' }}
            />
          </div>
          <Divider />
          <FormInput
            label="Course Name"
            fieldName="name"
            formContext={form}
            isRequired={true}
            fieldWidth="normal"
            formFieldOptions={{ required: 'Course Name is required.' }}
          />
          <FormWysiwyg
            fieldName="description"
            fieldWidth="full"
            formContext={form}
            formFieldOptions={{ required: 'Course description is required.' }}
            label="Course Description"
            labelWeight={500}
            tag="textarea"
          />
          <div style={{ marginTop: 30 }}>
            <FormWysiwyg
              fieldName="summary"
              fieldWidth="full"
              formContext={form}
              label="Course Summary"
              labelWeight={500}
              tag="textarea"
            />
          </div>
          <div>
            <FormLinksImage
              label="Display Image"
              uploadButtonText="Upload"
              existingAssetIdField="linksAssetId"
              fieldName="linkAssetUpload"
              formContext={form}
              defaultAssetId="itrs-default-course-image.png"
              useCropper={true}
              dropzoneSettings={{
                maxSize: 5 * 1024 * 1024, // 5MB
              }}
              croppedRatio={300 / 300}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'end' }}>
            <FormInput
              label="Duration"
              fieldName="duration"
              fieldWidth="short"
              formContext={form}
              type="number"
              {...inputPropsConfig}
              formFieldOptions={{ required: 'Duration is required.' }}
            />
            <p style={{ marginLeft: 10, fontWeight: 500 }}>days</p>
          </div>
          <Select
            labelKey="label"
            label="Category / Department"
            options={categories}
            formContext={form}
            placeholder={'Select Category'}
            fieldName="category"
            type="select"
            valueKey={'value'}
            fieldWidth="normal"
            fontWeight={500}
            findValueInOptions={true}
            formFieldOptions={{ required: 'This field is required.' }}
          />
          <MultiSelect
            isSingleSelect={false}
            labelKey="label"
            label="Program(s)"
            options={programs}
            formContext={form}
            placeholder={'Select Program'}
            fieldName="program"
            valueKey={'value'}
            findValueInOptions={true}
            formFieldOptions={{ required: false }}
          />

          <div style={{ marginBottom: 30, marginTop: 30 }}>
            <FormCheckBoxGroup
              id="enrollment-approval"
              label="Enrollment Approval"
              required={true}
              name="approvers"
              form={form}
              formFieldOptions={{
                required: 'At least one approver is required.',
              }}
              options={[
                { label: 'Point of Contact', value: 'point-of-contact' },
                { label: 'Program Coordinator', value: 'program-coordinator' },
              ]}
            />
          </div>

          <div style={{ display: 'flex', alignItems: 'end', marginBottom: 30 }}>
            <FormInput
              label="Enrollment Cut-Off"
              fieldName="enrollmentCutOff"
              formContext={form}
              fieldWidth="short"
              type="number"
              {...inputPropsConfig}
              formFieldOptions={{ required: 'Cut-off date is required.' }}
            />
            <p style={{ marginLeft: 10, fontWeight: 500 }}>days before start date of session</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <RadioButtonGroup
              labelWeight={500}
              name="certificate"
              defaultValue="no"
              form={form}
              label="Course Earns a Certificate"
              options={[
                { value: 'no', label: 'No' },
                { value: 'yes', label: 'Yes' },
              ]}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 15 }}>
            <RadioButtonGroup
              labelWeight={500}
              name={'ceu'}
              defaultValue="no"
              form={form}
              label="Course Earns a Continuing Education Credit (CEU)"
              options={[
                { value: 'no', label: 'No' },
                { value: 'yes', label: 'Yes' },
              ]}
            />
          </div>
          <div style={{ width: '60%' }}>
            <FormWysiwyg
              tag="textarea"
              label="Prerequisite(s)"
              labelWeight={500}
              formContext={form}
              fieldName="prereqs"
            />
          </div>
        </form>
        <br />
        <CmsPageSectionFooter actions={actions} />
      </CmsPage>
    </>
  );
};

export default ItrsCourseCreatePage;
