import { Box, TableCell } from '@mui/material';
import React, { useEffect, useRef, useMemo, useState } from 'react';
import { useTableEditorContext } from './TableContext';

const TableCellAction = ({
  cellAlign = 'left',
  cellWidth,
  field,
  idx,
  tableCellProps = {},
  viewOnly,
  setCursor,
  colIndex,
  hidden = false,
  action,
  minWidth,
}) => {
  const { editField, editIdx, fontSize } = useTableEditorContext();
  const [isLocalEditing, setLocalIsEditing] = useState(false);
  const inputRef = useRef(null);
  const isEditing = useMemo(
    () => editIdx === idx && editField === field,
    [editField, editIdx, idx, field]
  );

  useEffect(() => {
    if (setCursor && inputRef.current && colIndex === 0) {
      inputRef.current.focus();
    }
  }, [setCursor, colIndex]);

  useEffect(() => {
    if (isLocalEditing && !isEditing) {
      setLocalIsEditing(false);
    } else if (isEditing && !isLocalEditing) {
      setLocalIsEditing(true);
    }
  }, [isEditing, isLocalEditing]);

  return (
    <TableCell
      component="th"
      scope="row"
      onClick={() => action(idx, field)}
      align={cellAlign}
      sx={{
        minWidth,
        width: cellWidth,
        maxWidth: cellWidth,
        borderBottom: '1px solid #e0e0e0',
        margin: 0,
        fontSize,
        wordWrap: 'break-word',
        verticalAlign: 'center',
        position: 'relative',
        cursor: 'pointer',
        '&:hover': { ...(!viewOnly && { backgroundColor: 'rgba(0, 0, 0, 0.04)' }) },
        '& .MuiInputBase-root': { verticalAlign: 'middle', border: 'none' },
        '& .MuiInputBase-body': { padding: '8px' },
        '& .MuiInputBase-input': { padding: '0', '&::placeholder': { lineHeight: 'inherit' } },
        ...(hidden && { display: 'none' }),
      }}
      {...tableCellProps}
    >
      <Box sx={{ display: 'flex', height: '100%' }}>Edit</Box>
    </TableCell>
  );
};

export default TableCellAction;
