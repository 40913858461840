import { Box, TableCell, Select, MenuItem } from '@mui/material';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useTableEditorContext } from './TableContext';

const TableCellDropDown = ({
  cellAlign = 'left',
  cellWidth,
  colIndex,
  defaultViewValue,
  disabled,
  field,
  idx,
  minWidth,
  options = [],
  setCursor,
  tableCellProps = {},
  value,
  viewOnly,
}) => {
  const { editField, handleFieldClick, setRowField, editIdx, canDrag, fontSize } =
    useTableEditorContext();
  const [isLocalEditing, setLocalIsEditing] = useState(false);
  const selectRef = useRef(null);
  const isEditing = editIdx === idx && editField === field;

  useEffect(() => {
    if (setCursor && selectRef.current && colIndex === 0) {
      selectRef.current.focus();
    }
  }, [setCursor, colIndex]);

  useEffect(() => {
    if (isLocalEditing && !isEditing) {
      setLocalIsEditing(false);
    } else if (isEditing && !isLocalEditing) {
      setLocalIsEditing(true);
    }
  }, [isLocalEditing, setLocalIsEditing, isEditing]);

  const handleOnChange = useCallback(
    (e) => {
      const val = e.target.value;
      setRowField(idx, field, val);
    },
    [setRowField, idx, field]
  );

  return (
    <TableCell
      component="th"
      scope="row"
      onClick={() => handleFieldClick(idx, field)}
      align={cellAlign}
      sx={{
        minWidth,
        width: cellWidth,
        maxWidth: cellWidth,
        borderBottom: '1px solid #e0e0e0',
        margin: 0,
        fontSize,
        wordWrap: 'break-word',
        verticalAlign: 'center',
        position: 'relative',
        '&:hover': { ...(!viewOnly && { backgroundColor: 'rgba(0, 0, 0, 0.04)' }) },
        '& .MuiSelect-root': { verticalAlign: 'middle', border: 'none' },
        '& .MuiSelect-body': { padding: '8px' },
      }}
      {...tableCellProps}
    >
      <Box sx={{ display: 'flex', height: '100%' }}>
        {canDrag && colIndex === 0 && (
          <Box sx={{ display: 'flex', mr: 2, alignItems: 'center', justifyContent: 'center' }}>
            <i className="far fa-ellipsis-v reorder-icon" aria-hidden="true"></i>
            <i className="far fa-ellipsis-v reorder-icon" aria-hidden="true"></i>
          </Box>
        )}
        <Select
          ref={selectRef}
          value={value || defaultViewValue}
          onChange={handleOnChange}
          displayEmpty
          fullWidth
          variant="standard"
          disabled={viewOnly || disabled}
          sx={{
            fontSize,
            '& .MuiSelect-select': { padding: '0.5rem' },
            '& .MuiSelect-icon': { color: 'inherit' },
          }}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </TableCell>
  );
};

export default TableCellDropDown;
