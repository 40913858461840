import Check from '@mui/icons-material/CheckCircleOutline';
import Close from '@mui/icons-material/HighlightOff';
import map from 'lodash/map';
import moment from 'moment';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import popoverListColumn from '../../shared/generalComponents/popoverListColumn';
import useSheetModalUpload from '../../shared/cmsPage/hooks/useSheetModalUpload';
import { downloadJsonToCsv, downloadLocalWebFile } from '../../shared/util/fileDownload';
import { navigateToParentPage } from '../../store/rootReducer';
import attendanceApi, {
  getAllAttendees,
  getAttendanceResults,
  saveImportData,
  updateAttendeeAttendance,
} from '../api/attendanceApi';
import { AttendanceEventConstants } from '../definitions';

const { pluginId, defaultPageId, parentPageTitle } = AttendanceEventConstants;

const EventAttendeesCol = [
  {
    initialSort: 'ASC',
    label: 'Name of Attendee',
    sortable: true,
    valueField: 'name',
  },
  {
    initialSort: 'ASC',
    label: 'E-mail Address',
    sortable: true,
    valueField: 'email',
  },
  popoverListColumn('Group(s)', 'groups', 'fa-users', '', { align: 'left' }),
  {
    initialSort: 'ASC',
    label: 'Eligible to Attend Event',
    align: 'center',
    renderer: (item) => <div>{item?.isEligible ? 'Yes' : 'No'} </div>,
    sortable: false,
  },
  {
    initialSort: 'ASC',
    label: 'In Attendance',
    align: 'center',
    renderer: ({ showIcon, hasAttended } = {}) => {
      const Icon = hasAttended ? Check : Close;
      const words = hasAttended ? 'Yes' : 'No';
      return (
        <div>
          {showIcon ? (
            <Icon style={{ color: hasAttended ? 'green' : 'red', fontSize: '1.75rem' }} />
          ) : (
            words
          )}
        </div>
      );
    },
    sortable: false,
  },
];

export default function useAttendanceEventAttendeeListActions({
  attendanceEventId,
  handleClickShowAttendeeDetails,
  setShowList,
} = {}) {
  const [eventData, setEventData] = useState({ eventName: '', importTimestamp: '' });
  const [csvTitle, setCSVTitle] = useState('');
  const getEventData = useCallback(async () => {
    try {
      const data = await attendanceApi.loadRecord(attendanceEventId);
      setCSVTitle(data.eventName);
      setEventData({
        eventName: data.eventName || '',
        importTimestamp: data.importTimestamp
          ? moment(data.importTimestamp).format('MMMM D, YYYY, h:mm A')
          : '',
      });
    } catch {
      setEventData({ eventName: '', importTimestamp: '' });
    }
  }, [attendanceEventId, setEventData]);

  useEffect(() => {
    getEventData();
  }, [attendanceEventId]);

  const dispatch = useDispatch();
  const downloadTemplate = useMemo(
    () => ({
      link: true,
      label: 'Download Template',
      onClick: async () => {
        await downloadLocalWebFile('attendance-tracking-import-template.csv');
      },
    }),
    []
  );
  const onSaveImportHandler = useCallback(
    async (data) => {
      await saveImportData(attendanceEventId, data);
      await getEventData();
      setShowList(false);
      await new Promise((r) => setTimeout(r, 50));
      setShowList(true);
    },
    [setShowList, attendanceEventId, getEventData]
  );
  const { SheetModal, open } = useSheetModalUpload({
    onClear: () => {},
    onSaveButtons: [
      {
        onClick: async ({ data }) => {
          await onSaveImportHandler(map(data, 'badge-id'));
        },
        text: 'Import Scan',
        variant: 'success',
      },
    ],
    bodyText:
      'Notice: Importing a scan results file will overwrite any existing attendance logs for the selected event',
    sheetProps: {
      delimiter: ',',
      fieldValidators: { 'badge-id': 'truthy' },
      ignoreNoHeaderColumns: true,
      requiredHeaders: ['badge-id'],
      shouldParseColumnsAsJson: [{ 'badge-id': 'id' }],
      uniqueFields: [],
      postUniqueFilters: ['badge-id'],
      uniqueHeaders: true,
    },
    title: 'Import Scan',
  });
  const importAttendees = useMemo(() => ({ label: 'Import Attendees', onClick: open }), [open]);

  const exportResults = useMemo(
    () => ({
      label: 'Export Results',
      onClick: async () => {
        const attendees = await getAllAttendees(attendanceEventId);

        downloadJsonToCsv({
          filename: `${csvTitle}-attendance-results.csv`,
          fields: [
            'Name of Attendee',
            'E-mail Address',
            'Eligible to Attend Event',
            'In Attendance',
          ],
          data: attendees.map((a) => ({
            'Name of Attendee': a.name,
            'E-mail Address': a.email,
            'Eligible to Attend Event': a.isEligible ? 'Yes' : 'No',
            'In Attendance': a.hasAttended ? 'Yes' : 'No',
          })),
        });
      },
    }),
    [csvTitle]
  );

  const back = useMemo(
    () => ({
      onClick: () => dispatch(navigateToParentPage([pluginId, 'default', defaultPageId])),
      pageName: 'Attendance Tracking Events',
    }),
    [parentPageTitle, dispatch, pluginId]
  );

  const { itemActions, columns, api, toolbarActions } = useMemo(
    () => ({
      api: { loadRecords: getAttendanceResults },
      columns: EventAttendeesCol,
      itemActions: [
        {
          icon: ({ hasAttended } = {}) => `fa-solid fa-${hasAttended ? 'times' : 'check'}`,
          label: ({ hasAttended } = {}) => (hasAttended ? `Did not attend` : `Did attend`),
          onClick: async (item, { reload }) => {
            await updateAttendeeAttendance(attendanceEventId, item.id, !item.hasAttended);
            await reload();
          },
        },
      ],
      toolbarActions: [downloadTemplate, exportResults, importAttendees],
    }),
    [dispatch, handleClickShowAttendeeDetails, downloadTemplate, importAttendees, exportResults]
  );

  return {
    SheetModal,
    api,
    back,
    columns,
    itemActions,
    toolbarActions,
    importTimestamp: eventData.importTimestamp,
    eventName: eventData.eventName,
  };
}
