import { Box, TableCell, TextField, Checkbox } from '@mui/material';
import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { useTableEditorContext } from './TableContext';
import _ from 'lodash';

const TableCellTextField = ({
  cellAlign = 'left',
  cellWidth,
  field,
  idx,
  tableCellProps = {},
  textFieldProps = {},
  inputType,
  viewOnly,
  error,
  setCursor,
  value,
  transformValue,
  colIndex,
  defaultViewValue,
  hidden = false,
  disabled,
  minWidth,
}) => {
  const { editField, handleFieldClick, setRowField, editIdx, canDrag, fontSize } =
    useTableEditorContext();
  const [isLocalEditing, setLocalIsEditing] = useState(false);
  const inputRef = useRef(null);
  const isEditing = useMemo(
    () => editIdx === idx && editField === field,
    [editField, editIdx, idx, field]
  );

  useEffect(() => {
    if (setCursor && inputRef.current && colIndex === 0) {
      inputRef.current.focus();
    }
  }, [setCursor, colIndex]);

  useEffect(() => {
    if (isLocalEditing && !isEditing) {
      setLocalIsEditing(false);
    } else if (isEditing && !isLocalEditing) {
      setLocalIsEditing(true);
    }
  }, [isEditing, isLocalEditing]);

  const handleOnChange = useCallback(
    (e) => {
      let val = inputType === 'checkbox' ? e.target.checked : e.target.value;
      if (inputType === 'number' && val !== '' && _.isNumber(min) && Number(val) < min) {
        e.preventDefault();
        return;
      }
      if (transformValue && typeof transformValue === 'function') {
        val = transformValue(val);
      }
      setRowField(idx, field, val);
    },
    [setRowField, idx, field, inputType, min, transformValue]
  );

  const min = textFieldProps?.inputProps?.min;

  const textFieldPropsComputed = useMemo(() => {
    if (isEditing) {
      return {
        value:
          value === undefined && defaultViewValue !== undefined ? defaultViewValue : value || '',
      };
    }
    return {
      defaultValue:
        value === undefined && defaultViewValue !== undefined ? defaultViewValue : value || '',
    };
  }, [isEditing, value, defaultViewValue]);

  return (
    <TableCell
      component="th"
      scope="row"
      onClick={() => handleFieldClick(idx, field)}
      align={cellAlign}
      sx={{
        minWidth,
        width: cellWidth,
        maxWidth: cellWidth,
        borderBottom: '1px solid #e0e0e0',
        margin: 0,
        fontSize,
        wordWrap: 'break-word',
        verticalAlign: 'center',
        position: 'relative',
        '&:hover': { ...(!viewOnly && { backgroundColor: 'rgba(0, 0, 0, 0.04)' }) },
        '& .MuiInputBase-root': { verticalAlign: 'middle', border: 'none' },
        '& .MuiInputBase-body': { padding: '8px' },
        '& .MuiInputBase-input': { padding: '0', '&::placeholder': { lineHeight: 'inherit' } },
        ...(hidden && { display: 'none' }),
      }}
      {...tableCellProps}
    >
      <Box sx={{ display: 'flex', height: '100%' }}>
        {canDrag && colIndex === 0 && (
          <Box sx={{ display: 'flex', mr: 2, alignItems: 'center', justifyContent: 'center' }}>
            <i className="far fa-ellipsis-v reorder-icon" aria-hidden="true"></i>
            <i className="far fa-ellipsis-v reorder-icon" aria-hidden="true"></i>
          </Box>
        )}
        {inputType === 'checkbox' ? (
          <Checkbox
            checked={!!value}
            onChange={handleOnChange}
            sx={{ padding: '0.5rem' }}
            disabled={viewOnly || disabled}
            inputRef={inputRef}
          />
        ) : (
          <TextField
            inputRef={inputRef}
            size="small"
            variant="standard"
            margin="dense"
            disabled={viewOnly || disabled}
            sx={{
              '& .MuiInput-root': { borderBottom: 'none', fontSize },
              '& .MuiInputBase-root': { fontSize },
              '& .MuiInputBase-input': {
                fontSize,
                color: 'inherit',
                ...((viewOnly || inputType !== 'number') && { px: '.5rem' }),
              },
              '& .MuiInput-underline:before': { borderBottom: 'none' },
              '& .MuiInput-underline:after': {
                borderBottom: inputType === 'checkbox' || viewOnly ? 'none' : '1px solid black',
              },
              '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottom: 'none' },
            }}
            type={inputType}
            autoFocus
            helperText={error?.message}
            error={!!error}
            onKeyDown={(e) => {
              if (inputType === 'number' && e.key === '-' && min === 0) {
                e.preventDefault();
              }
            }}
            onChange={handleOnChange}
            fullWidth
            InputProps={{
              readOnly: viewOnly,
            }}
            {...textFieldProps}
            {...textFieldPropsComputed}
          />
        )}
      </Box>
    </TableCell>
  );
};

export default TableCellTextField;
