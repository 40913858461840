import { has } from 'lodash';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import BackButton from '../../shared/cmsPage/components/BackButton';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsPageSection from '../../shared/cmsPage/components/CmsPageSection';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import FormDate from '../../shared/form/components/FormDate';
import FormInput from '../../shared/form/components/FormInput';
import MultiSelect from '../../shared/form/components/MultiSelect';
import Select from '../../shared/form/components/Select';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import GrowlContainer from '../../shared/growl/components/GrowlContainer';
import leadGenEventApi, { getLeadGenGroups, getLeadGenIngestTypes } from '../api/leadGenEventApi';
import { LeadGenEventConstants } from '../definitions';
export default function LeadGenEventEditorPage({ leadGenEventId = 'new' }) {
  const [showExternalId, setShowExternalId] = useState(false);
  const [eventGroupsOptions, setEventGroupsOptions] = useState([]);
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const { item, loadItem, saveItem, requestPending } = useItemPersistence(
    leadGenEventApi,
    leadGenEventId
  );
  const form = useCmsForm(item);

  const ingestType = useWatch({ control: form.control, name: 'ingestType' });
  const startDate = useWatch({ control: form.control, name: 'startDate' });
  const endDate = useWatch({ control: form.control, name: 'endDate' });

  const loadEventGroupsOptions = useCallback(async () => {
    const options = await getLeadGenGroups();
    setEventGroupsOptions(options);
    setOptionsLoaded(true);
  }, []);

  useEffect(() => {
    loadEventGroupsOptions();
  }, [loadEventGroupsOptions]);

  useEffect(() => {
    if (ingestType === 'api' || ingestType === 'both') {
      setShowExternalId(true);
      form.register('externalId', { required: 'External API ID is required.' });
    } else {
      setShowExternalId(false);
      form.unregister('externalId');
    }
  }, [ingestType, form]);

  useEffect(() => {
    if (leadGenEventId && optionsLoaded) {
      (async () => {
        const loadedItem = await loadItem(leadGenEventId);
        if (loadedItem) {
          const newItem = { ...loadedItem };
          if (newItem.startDate) {
            const parsed = moment(newItem.startDate, moment.ISO_8601, true);
            if (parsed.isValid()) {
              newItem.startDate = parsed.format('YYYY-MM-DDTHH:mm');
            }
          }
          if (newItem.endDate) {
            const parsed = moment(newItem.endDate, moment.ISO_8601, true);
            if (parsed.isValid()) {
              newItem.endDate = parsed.format('YYYY-MM-DDTHH:mm');
            }
          }
          form.reset(newItem);
        }
      })();
    }
  }, [leadGenEventId, loadItem, form, optionsLoaded]);

  const actions = useItemEditActions(saveItem, form, LeadGenEventConstants, leadGenEventId);

  const { pageTitle, onItemChange } = useEditPageTitle(leadGenEventId, 'LeadGenEvent', 'name');
  useEffect(() => onItemChange(item), [item, onItemChange]);

  const loadIngestTypeOptions = useCallback(async () => {
    const options = await getLeadGenIngestTypes();
    if (options.length === 1) {
      form.setValue('ingestType', options[0].value);
    }
    return options;
  }, [form]);

  const isLoading = requestPending || !optionsLoaded;

  return (
    <CmsPage title={pageTitle} requestPending={isLoading}>
      <CmsPageSection className="cohort-editor">
        {isLoading ? (
          <div>Loading form data...</div>
        ) : (
          <>
            <div className="row">
              <BackButton
                pageName="Lead Gen Events"
                navToParent
                navParams={[
                  LeadGenEventConstants.pluginId,
                  'default',
                  LeadGenEventConstants.pageTitle,
                ]}
              />
            </div>
            <GrowlContainer groupId={LeadGenEventConstants.editorPageId} />
            <div className="row">
              <form role="form" className="no-left-padding">
                <FormInput
                  label="Event Name"
                  fieldName="eventName"
                  formContext={form}
                  required
                  formFieldOptions={{
                    required: 'This field is required.',
                  }}
                />
                <FormDate
                  localTime
                  localTimezone
                  label="Start Date"
                  fieldName="startDate"
                  formContext={form}
                  formFieldOptions={{
                    validate: {
                      endDateBeforeStartDate: (startDateVal) => {
                        if (endDate && startDateVal && moment(startDateVal).isAfter(endDate)) {
                          return 'Start Date Must Be Before End Date';
                        }
                        return true;
                      },
                    },
                  }}
                />
                <div style={{ marginTop: '40px' }}>
                  <FormDate
                    localTime
                    localTimezone
                    label="End Date"
                    fieldName="endDate"
                    formContext={form}
                    formFieldOptions={{
                      validate: {
                        startDateBeforeEndDate: (endDateVal) => {
                          if (startDate && endDateVal && moment(endDateVal).isBefore(startDate)) {
                            return 'End Date Must Be After Start Date';
                          }
                          return true;
                        },
                      },
                    }}
                  />
                </div>
                <FormInput
                  label="Location"
                  fieldName="location"
                  formContext={form}
                  placeholder="Enter Location"
                />
                <MultiSelect
                  fieldName="eventGroups"
                  formContext={form}
                  formFieldOptions={{ required: 'This field is required.' }}
                  label="Event Groups"
                  labelKey="name"
                  limitTags={20}
                  options={eventGroupsOptions}
                  placeholder="Select Groups"
                  sortTagsKey="name"
                  isOptionEqualToValue={(opt, value) => {
                    if (has(opt, 'id')) {
                      return opt.id === value.id;
                    } else if (has(opt, 'key')) {
                      return opt.key === value.key;
                    }
                    return isEqual(opt, value);
                  }}
                />
                <Select
                  label="Data Source"
                  optionsLoader={loadIngestTypeOptions}
                  formContext={form}
                  fieldName="ingestType"
                />
                {showExternalId && (
                  <FormInput
                    label="External Api Id"
                    fieldName="externalId"
                    formContext={form}
                    placeholder="Enter external cohort ID."
                    formFieldOptions={{
                      required: 'External API ID is required.',
                    }}
                  />
                )}
              </form>
            </div>
            <div className="col col-lg-12 col-md-12 col-sm-12">
              <div className="row module-footer">
                <CmsPageSectionFooter actions={actions} />
              </div>
            </div>
          </>
        )}
      </CmsPageSection>
    </CmsPage>
  );
}
