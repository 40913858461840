import Box from '@mui/material/Box';
import moment from 'moment';
import React from 'react';
import FormCheckBox from '../../../../shared/form/components/FormCheckBox';
import FormDate from '../../../../shared/form/components/FormDate';
const getDateOnly = (date) => (date ? moment(date).startOf('day').toDate() : null);

export default function TravelHorizons({ form, parsedTravelDates = {}, parsedSessionDates = {} }) {
  const { watch } = form;
  const { departureStartDate, departureEndDate, arrivalStartDate, arrivalEndDate } =
    parsedTravelDates;
  const { endDate, startDate } = parsedSessionDates;
  const arrivalStart = getDateOnly(arrivalStartDate);
  const arrivalEnd = getDateOnly(arrivalEndDate);
  const sessionStart = getDateOnly(startDate);
  const travelHorizons = watch('travelHorizons');
  const today = moment().startOf('day').toDate();
  return (
    <>
      <div style={{ marginTop: 10 }}>
        <FormCheckBox
          id={'travelhorizons'}
          label="Enable Travel Horizons"
          tooltipType="question"
          infoTooltip="Use this option to set travel arrival and departure dates for a session. If defined, attendee travel dates must be within horizon guidelines or an explanation and additional approval requirements will be required."
          fieldName={`travelHorizons`}
          formContext={form}
          preChecked={travelHorizons}
          type="checkbox"
        />
      </div>

      <div style={{ display: travelHorizons ? 'block' : 'none' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Box
            sx={{
              width: '34em',
              height: 220,
              borderRadius: 1,
              padding: 2,
              bgcolor: '#EEEEEE',
              marginRight: 4,
              marginBottom: 2,
            }}
          >
            <div>
              <div style={{ fontWeight: 700, marginBottom: 10 }}>Arrival Horizon</div>
              <FormDate
                label={`Start Date/Time`}
                fieldName="arrivalStartDate"
                fieldWidth="normal"
                formContext={form}
                formFieldOptions={{
                  validate: (value) => {
                    const travelCheck = watch('travelHorizons');
                    if (!travelCheck) return true;
                    if (!value) return 'Arrival Start Date is required';
                    if (!arrivalStart) return 'Invalid arrival start date';
                    if (arrivalStart < today) return 'Arrival Start Date cannot be in the past';
                    if (sessionStart && arrivalStart > sessionStart)
                      return 'Arrival Start Date cannot be after the Session Start Date';
                    if (arrivalEnd && arrivalStart > arrivalEnd)
                      return 'Arrival Start Date cannot be after Arrival End Date';
                    return true;
                  },
                }}
                inputProps={{
                  min: today.toISOString().split('T')[0], // Set minimum date as today
                }}
              />
            </div>
            <div>
              <FormDate
                label={`End Date/Time`}
                fieldName="arrivalEndDate"
                fieldWidth="normal"
                formContext={form}
                formFieldOptions={{
                  validate: (value) => {
                    const travelCheck = watch('travelHorizons');
                    if (!travelCheck) return true;
                    if (!value) return 'Arrival End Date is required';
                    if (!arrivalEnd) return 'Invalid arrival end date';
                    if (arrivalStart && arrivalEnd < arrivalStart)
                      return 'Arrival End Date cannot be before Arrival Start Date';
                    if (sessionStart && arrivalEnd > sessionStart)
                      return 'Arrival End Date cannot be after the Session Start Date';
                    return true;
                  },
                }}
              />
            </div>
          </Box>

          <Box
            sx={{
              width: '34em',
              height: 220,
              borderRadius: 1,
              padding: 2,
              bgcolor: '#EEEEEE',
            }}
          >
            <div>
              <div style={{ fontWeight: 700, marginBottom: 10 }}>Departure Horizon</div>
              <FormDate
                label={`Start Date/Time`}
                fieldName="departureStartDate"
                fieldWidth="normal"
                formContext={form}
                formFieldOptions={{
                  validate: (value) => {
                    const travelCheck = watch('travelHorizons');
                    if (!travelCheck) return true;
                    if (!value) return 'Departure Start date is required';
                    if (!departureStartDate) return 'Invalid departure start date';
                    if (arrivalEndDate && departureStartDate < arrivalEndDate)
                      return 'Departure Start Date cannot be before Arrival End Date';
                    if (endDate && departureStartDate < endDate) {
                      return 'Departure Start Date cannot be before Session End Date';
                    }

                    return true;
                  },
                }}
              />
            </div>
            <div>
              <FormDate
                label={`End Date/Time`}
                fieldName="departureEndDate"
                fieldWidth="normal"
                formContext={form}
                formFieldOptions={{
                  validate: (value) => {
                    const travelCheck = watch('travelHorizons');
                    if (!travelCheck) return true;
                    if (!value) return 'Departure End date is required';
                    if (!departureEndDate) return 'Invalid departure end date';
                    if (departureStartDate && departureEndDate < departureStartDate)
                      return 'Departure End Date cannot be before Departure Start Date';
                    return true;
                  },
                }}
              />
            </div>
          </Box>
        </div>
      </div>
    </>
  );
}
