import Divider from '@mui/material/Divider';
import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import log from '../../../logger';
import SessionDates from './components/SessionDates';
import SessionDetails from './components/SessionDetails';
import SessionStatus from './components/SessionStatus';
import TravelHorizons from './components/TravelHorizons';
export const inputPropsConfig = {
  inputProps: {
    min: '0',
    sx: {
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        background: 'transparent',
        border: 'none',
        opacity: 1,
        display: 'block',
      },
      MozAppearance: 'textfield',
    },
  },
};

const SessionDetailForm = ({ form, courseDuration, sessionId, sessionName }) => {
  const { watch, setValue, trigger, formState } = form;

  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');
  const watchArrivalStartDate = watch('arrivalStartDate');
  const watchArrivalEndDate = watch('arrivalEndDate');
  const watchDepartureStartDate = watch('departureStartDate');
  const watchDepartureEndDate = watch('departureEndDate');
  const travelHorizons = watch('travelHorizons');
  useEffect(() => {
    if (sessionId === 'new' && !formState.dirtyFields.name && watchStartDate && watchEndDate) {
      const formattedStartDate = moment(watchStartDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      const formattedEndDate = moment(watchEndDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      setValue('name', `${formattedStartDate} - ${formattedEndDate}`, { shouldValidate: true });
    }
  }, [watchStartDate, watchEndDate, sessionId, formState.dirtyFields.name, setValue]);

  const parsedSessionDates = useMemo(() => {
    const parseDate = (dateString) => {
      if (!dateString) return null;
      const [year, month, day] = dateString.split('-').map(Number);
      return new Date(year, month - 1, day);
    };

    const startDate = parseDate(watchStartDate);
    const endDate = parseDate(watchEndDate);

    const getNextDay = (date) => {
      if (!date) return null;
      const nextDay = new Date(date);
      nextDay.setDate(date.getDate() + 1);
      return nextDay;
    };

    const getDayBefore = (date) => {
      if (!date) return null;
      const dayBefore = new Date(date);
      dayBefore.setDate(date.getDate() - 1);
      return dayBefore;
    };

    return {
      startDate,
      endDate,
      dayBeforeStartDate: getDayBefore(startDate),
      watchEndDateNextDay: getNextDay(endDate),
    };
  }, [watchStartDate, watchEndDate]);

  const parsedTravelDates = useMemo(() => {
    const parseDateTime = (dateTimeString) => {
      if (!dateTimeString) return null;
      const [datePart, timePart] = dateTimeString.split('T');
      const [year, month, day] = datePart.split('-').map(Number);
      const [hour = 0, minute = 0] = timePart ? timePart.split(':').map(Number) : [];
      return new Date(year, month - 1, day, hour, minute);
    };

    return {
      arrivalStartDate: parseDateTime(watchArrivalStartDate),
      arrivalEndDate: parseDateTime(watchArrivalEndDate),
      departureStartDate: parseDateTime(watchDepartureStartDate),
      departureEndDate: parseDateTime(watchDepartureEndDate),
    };
  }, [watchArrivalStartDate, watchArrivalEndDate, watchDepartureStartDate, watchDepartureEndDate]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const toDatetimeLocal = (date) => {
    if (!date) return '';
    const pad = (num) => String(num).padStart(2, '0');
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  useEffect(() => {
    if (
      parsedSessionDates.startDate &&
      !Number.isNaN(parsedSessionDates.startDate.getTime()) &&
      courseDuration &&
      !Number.isNaN(Number.parseInt(courseDuration, 10)) &&
      !formState.dirtyFields.endDate
    ) {
      const duration = Number.parseInt(courseDuration, 10);
      const calculatedEndDate = new Date(parsedSessionDates.startDate);
      calculatedEndDate.setDate(parsedSessionDates.startDate.getDate() + duration);

      if (Number.isNaN(calculatedEndDate.getTime())) {
        log.error('Invalid calculatedEndDate:', calculatedEndDate);
      } else {
        const endDateValue = calculatedEndDate.toISOString().split('T')[0];
        setValue('endDate', endDateValue, { shouldValidate: true });
        trigger(['startDate', 'endDate']);
      }
    }
  }, [
    parsedSessionDates.startDate,
    courseDuration,
    formState.dirtyFields.endDate,
    setValue,
    trigger,
  ]);

  useEffect(() => {
    if (formState.dirtyFields.endDate) {
      trigger(['departureStartDate', 'departureEndDate']);
    }
  }, [watchEndDate, formState.dirtyFields.endDate, trigger]);

  useEffect(() => {
    if (travelHorizons && parsedSessionDates.startDate && parsedSessionDates.endDate) {
      const arrivalStartDateTime = new Date(parsedSessionDates.dayBeforeStartDate);
      arrivalStartDateTime.setHours(8, 0, 0, 0);
      const arrivalEndDateTime = new Date(parsedSessionDates.dayBeforeStartDate);
      arrivalEndDateTime.setHours(23, 0, 0, 0);
      const departureStartDateTime = new Date(parsedSessionDates.endDate);
      departureStartDateTime.setHours(15, 0, 0, 0);
      const departureEndDateTime = new Date(parsedSessionDates.watchEndDateNextDay);
      departureEndDateTime.setHours(12, 0, 0, 0);
      setValue('arrivalStartDate', toDatetimeLocal(arrivalStartDateTime), { shouldValidate: true });
      setValue('arrivalEndDate', toDatetimeLocal(arrivalEndDateTime), { shouldValidate: true });
      setValue('departureStartDate', toDatetimeLocal(departureStartDateTime), {
        shouldValidate: true,
      });
      setValue('departureEndDate', toDatetimeLocal(departureEndDateTime), { shouldValidate: true });

      trigger(['arrivalStartDate', 'arrivalEndDate', 'departureStartDate', 'departureEndDate']);
    } else if (!travelHorizons) {
      setValue('arrivalStartDate', '');
      setValue('arrivalEndDate', '');
      setValue('departureStartDate', '');
      setValue('departureEndDate', '');
    }
  }, [
    travelHorizons,
    parsedSessionDates.startDate,
    parsedSessionDates.endDate,
    parsedSessionDates.dayBeforeStartDate,
    parsedSessionDates.watchEndDateNextDay,
    setValue,
    trigger,
  ]);

  return (
    <>
      <div
        style={{
          width: '100%',
          padding: 10,
          fontWeight: 'bold',
          backgroundColor: '#1B76D2',
          color: 'white',
          marginBottom: 20,
        }}
      >
        <p style={{ marginBottom: 0, marginLeft: 10 }}>Session Details</p>
      </div>
      <SessionStatus form={form} sessionId={sessionId} />
      <Divider sx={{ my: 4 }} />
      <SessionDates form={form} parsedSessionDates={parsedSessionDates} sessionName={sessionName} />
      <TravelHorizons
        form={form}
        parsedTravelDates={parsedTravelDates}
        parsedSessionDates={parsedSessionDates}
      />
      <Divider sx={{ mt: 4 }} />
      <SessionDetails form={form} />
    </>
  );
};

export default SessionDetailForm;
