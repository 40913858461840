import React, { useEffect, useState } from 'react';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import AudienceSelector from '../../shared/form/components/AudienceSelector';
import FormInput from '../../shared/form/components/FormInput';
import FormRadioButton from '../../shared/form/components/FormRadioButton';
import FormInputTextArea from '../../shared/form/components/FormInputTextArea';
import LinkFinder from '../../shared/form/components/LinkFinder';
import FormDate from '../../shared/form/components/FormDate';
import moment from 'moment';

const PushNotificationEditorForm = ({
  form,
  growlId,
  actions,
  viewOnly,
  isFuture,
  isFutureScheduled,
}) => {
  const { watch, setValue, formState, trigger } = form;
  const [hasManuallySetType, setHasManuallySetType] = useState(false);
  const scheduleType = watch('scheduleType', isFuture ? 'later' : 'now');
  const sendTimestamp = watch('sendTimestamp', new Date());
  const isFutureDefault = isFutureScheduled && !hasManuallySetType;
  const isSubmitDisabled =
    scheduleType === 'later' && (!sendTimestamp || formState.errors.sendTimestamp);

  useEffect(() => {
    if (scheduleType === 'now') {
      setValue('sendTimestamp', '');
    }
  }, [scheduleType, setValue, trigger]);

  return (
    <div className="row">
      <form role="form" className="no-left-padding">
        <FormInput
          disabled={viewOnly}
          label="Time (in minutes) the notification is available to be received"
          fieldName="ttl"
          formContext={form}
          formFieldOptions={{ required: 'This field is required.' }}
        />

        <AudienceSelector
          disabled={viewOnly}
          formContext={form}
          label="Send Notification To"
          fieldName="audienceExpr"
          growlId={growlId}
          isRequired={false}
        />

        <label>Send Notification</label>

        <div className="push-notification-radios">
          <FormRadioButton
            disabled={viewOnly}
            id="immediate"
            key="immediate"
            name="scheduleType"
            label={
              <span>
                {'Immediately'} <span className="form-input-description"></span>
              </span>
            }
            checked={scheduleType === 'now' && !isFutureDefault}
            fieldName="scheduleType"
            onChange={() => {
              setValue('scheduleType', 'now');
              setHasManuallySetType(true);
            }}
            formContext={form}
            type="radio"
            value="now"
          />

          <FormRadioButton
            disabled={viewOnly}
            id="later"
            key="later"
            name="scheduleType"
            label={
              <span>
                {'Later'} <span className="form-input-description"></span>
              </span>
            }
            checked={scheduleType === 'later' || isFutureDefault}
            fieldName="scheduleType"
            onChange={() => {
              setValue('scheduleType', 'later');
              setHasManuallySetType(true);
            }}
            formContext={form}
            type="radio"
            value="later"
          />

          {(scheduleType === 'later' || isFutureDefault) && (
            <FormDate
              label="Push Notification Date/Time"
              sendTimestamp={sendTimestamp}
              fieldName="sendTimestamp"
              fieldWidth="normal"
              formContext={form}
              defaultStateValue={sendTimestamp}
              formFieldOptions={{
                required: true,
                validate: (date) => {
                  if (scheduleType !== 'later') {
                    return true;
                  }
                  if (!date) return 'Notification date/time is required';
                  if (moment(date).isBefore(moment().add(5, 'minutes'))) {
                    return 'Notification date/time must be at least 5 minutes in the future';
                  }

                  return true;
                },
              }}
            />
          )}
        </div>

        <FormInput
          disabled={viewOnly}
          label="Notification Subject"
          fieldName="title"
          formContext={form}
          formFieldOptions={{ required: 'This field is required.' }}
        />
        <FormInputTextArea
          disabled={viewOnly}
          label="Text for Notification"
          maxLength={1024}
          fieldName="message"
          formContext={form}
          formFieldOptions={{ required: 'This field is required.' }}
        />
        <FormInput
          disabled={viewOnly}
          label="Notification Target Url Link Text"
          fieldName="linkText"
          formContext={form}
        />

        <LinkFinder
          disabled={viewOnly}
          defaultValue=""
          formContext={form}
          label="Notification Target Url"
          fieldName="url"
          allowEmbeddedMenus={true}
          required={false}
        />
      </form>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} isSubmitDisabled={isSubmitDisabled} />
      </div>
    </div>
  );
};

export default PushNotificationEditorForm;
