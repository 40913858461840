import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { checkCanPublish } from './store/thunks';
import { selectHasPrivileges } from '../../store/rootSelectors';
import { useSelector } from 'react-redux';

export default function useSnapshotChecker() {
  const [hasChecked, setHasChecked] = useState(false);
  const hasPublishPrivelege = useSelector(selectHasPrivileges('cms.manageContent'));
  const dispatch = useDispatch();
  useEffect(() => {
    if (!hasChecked && hasPublishPrivelege) {
      dispatch(checkCanPublish({ hasChecked: false }));
      setHasChecked(true);
    }
  }, [dispatch, checkCanPublish, hasPublishPrivelege, hasChecked, setHasChecked]);
}
