import { EMPTY_PUSH_NOTIFICATION } from '../definitions';
import axios from 'axios';
import moment from 'moment';
import log from '../../logger';
const post = async (path, ...params) => {
  const { data } = await axios.post(`/push/${path}`, ...params);
  return data;
};
const get = async (path, ...params) => {
  const { data } = await axios.get(`/push/${path}`, ...params);
  return data;
};

const loadRecords = async (params) => {
  try {
    const { notifications, count, offset } = await get(`getPushNotifications`, {
      params: { ...params, sort: { sortField: 'publishTimestamp', order: 'DESC' } },
      timeout: 10_000,
    });
    return {
      records: notifications,
      numRecords: count,
      offset,
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load pushNotifications');
  }
};

const loadRecord = async (id) => {
  if (id === 'new') return EMPTY_PUSH_NOTIFICATION;

  try {
    const data = await get(`getPushNotification`, {
      params: {
        id,
      },
      timeout: 10_000,
    });
    const notification = data?.notification;
    let parsedCondition;
    try {
      parsedCondition = notification && JSON.parse(notification.audienceExpr);
      if (parsedCondition) notification.audienceExpr = parsedCondition;
    } catch {
      parsedCondition = '';
    }

    try {
      notification.sendTimestamp = moment(notification.sendTimestamp).format(
        moment.HTML5_FMT.DATETIME_LOCAL
      );
    } catch {
      log.info('could not parse sendTimestamp');
    }
    return notification;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load pushNotification.');
  }
};

const saveRecord = async (item) => {
  try {
    const props = {
      audienceExpr: item.audienceExpr || {},
      linkText: item.linkText,
      message: item.message,
      sendTimestamp: item.sendTimestamp ? moment(item.sendTimestamp).toISOString() : undefined,
      title: item.title,
      ttlMinutes: item.ttl,
      url: item.url,
    };
    if (item.id) {
      const { notification } = await post(`editPushNotification`, {
        notificationId: item.id,
        ...props,
      });
      return notification;
    }
    const { results } = await post(`schedulePushNotification`, {
      scheduleType: item.scheduleType,
      ...props,
    });
    return results;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save pushNotification.');
  }
};

const archiveRecord = async (id) => {
  try {
    const { pushNotification } = await post(
      `updatePushNotificationOptions`,
      { isArchived: true, id },
      { timeout: 10_000 }
    );
    return pushNotification;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save pushNotification.');
  }
};

const unarchiveRecord = async (id) => {
  try {
    const { pushNotification } = await post(
      `updatePushNotificationOptions`,
      { isArchived: false, id },
      { timeout: 10_000 }
    );
    return pushNotification;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save pushNotification.');
  }
};

const removeRecord = async ({ id }) => {
  try {
    return post(`removePushNotification`, {}, { params: { notificationId: id } });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove pushNotification.');
  }
};

const restoreRecord = async ({ id }) => {
  try {
    await post(`restorePushNotification`, { id }, { timeout: 10_000 });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to restore pushNotification.');
  }
};

const publishRecord = async (id) => {
  try {
    const { pushNotification } = await post(`publishPushNotification`, { id }, { timeout: 10_000 });
    return pushNotification;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to publish pushNotification.');
  }
};

export const cancelPushNotification = async (notificationId) => {
  try {
    return post(`cancelPushNotification`, {}, { params: { notificationId } });
  } catch {
    throw new Error('Failed to cancel push notification');
  }
};

export const editPushNotification = async (notificationId, props = {}) => {
  try {
    const { notification } = await post(`modifyPushNotification`, { ...props, notificationId });
    return notification;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to edit notification.');
  }
};

const pushNotificationApi = {
  archiveRecord,
  editPushNotification,
  loadRecord,
  loadRecords,
  publishRecord,
  removeRecord,
  restoreRecord,
  saveRecord,
  unarchiveRecord,
};
export default pushNotificationApi;
