import React, { useEffect } from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useRefreshActionCallback from '../../shared/cmsPage/hooks/useRefreshActionCallback';
import pushNotificationApi from '../api/pushNotificationApi';
import { PushNotificationConstants } from '../definitions';
import usePushColumnsSearch from '../hooks/usePushColumnsSearch';
import usePushNotificationActions from '../hooks/usePushNotificationActions';

const { defaultPageId, typeName } = PushNotificationConstants;

const PushNotificationsListPage = () => {
  const { hiddenButton, refreshCallback } = useRefreshActionCallback(defaultPageId);
  const { autoRefresh, toolbarActions, itemActions } = usePushNotificationActions(
    PushNotificationConstants,
    hiddenButton
  );
  const { columns, additionalSearchFields } = usePushColumnsSearch();
  useEffect(() => {
    if (!autoRefresh) return;
    const interval = setInterval(refreshCallback, 30_000);
    return () => clearInterval(interval);
  }, [autoRefresh, refreshCallback]);

  return (
    <CmsPage title="Push Notifications">
      <CmsItemListSection
        pageId={defaultPageId}
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={columns}
        itemApi={pushNotificationApi}
        additionalSearchFields={additionalSearchFields}
      />
    </CmsPage>
  );
};

export default PushNotificationsListPage;
